import { CHAIN_NAMESPACES, CustomChainConfig } from "@web3auth/base";

export const CHAIN_CONFIG = {
  mainnet: {
    displayName: "Ethereum Mainnet",
    chainNamespace: CHAIN_NAMESPACES.EIP155,
    chainId: "0x1",
    rpcTarget: `https://mainnet.infura.io/v3/776218ac4734478c90191dde8cae483c`,
    blockExplorer: "https://etherscan.io/",
    ticker: "ETH",
    tickerName: "Ethereum",
  } as CustomChainConfig,
  solana: {
    chainNamespace: CHAIN_NAMESPACES.SOLANA,
    rpcTarget: "https://api.mainnet-beta.solana.com",
    blockExplorer: "https://explorer.solana.com/",
    chainId: "0x1",
    displayName: "Solana Mainnet",
    ticker: "SOL",
    tickerName: "Solana",
  } as CustomChainConfig,
  polygon: {
    chainNamespace: CHAIN_NAMESPACES.EIP155,
    rpcTarget: "https://polygon-rpc.com",
    blockExplorer: "https://polygonscan.com/",
    chainId: "0x89",
    displayName: "Polygon Mainnet",
    ticker: "matic",
    tickerName: "Matic",
  } as CustomChainConfig,
  omchain: {
    chainNamespace: 'other',
    rpcTarget: "https://rpc.omplatform.com/",
    blockExplorer: "https://evm-explorer.omplatform.com/",
    chainId: "0x4DE",
    displayName: "Om Mainnet",
    ticker: "om",
    tickerName: "OM",
  } as unknown as CustomChainConfig,
  central: {
    chainNamespace: 'other',
    rpcTarget: "https://rpc.omplatform.com/",
    blockExplorer: "https://evm-explorer.omplatform.com/",
    chainId: "0x4DE",
    displayName: "Central Chain Mainnet",
    ticker: "ccoin",
    tickerName: "CCOIN",
  } as unknown as CustomChainConfig,
  bangna: {
    // chainNamespace: 'other',
    chainNamespace: CHAIN_NAMESPACES.EIP155,
    rpcTarget: "https://rpc.omplatform.com/",
    blockExplorer: "https://evm-explorer.omplatform.com/",
    chainId: "0x4DE",
    displayName: "OM Chain Mainnet",
    ticker: "om",
    tickerName: "OM",
  } as unknown as CustomChainConfig,
  rinkeby: {
    chainNamespace: CHAIN_NAMESPACES.EIP155,
    chainId: "0x4",
    rpcTarget: `https://rinkeby.infura.io/v3/afe510d8806f48ca92693b2621ecf595`,
    displayName: "rinkeby",
    blockExplorer: "https://rinkeby.etherscan.io/",
    ticker: "ETH",
    tickerName: "Ethereum",
  } as CustomChainConfig,
  bsc: {
    chainNamespace: CHAIN_NAMESPACES.EIP155,
    rpcTarget: "https://data-seed-prebsc-1-s2.binance.org:8545/",
    blockExplorer: "https://testnet.bscscan.com/",
    chainId: "0x61",
    displayName: "Binance Smart Chain",
    ticker: "bnb",
    tickerName: "BNB",
  } as unknown as CustomChainConfig,
} as const;

export type CHAIN_CONFIG_TYPE = keyof typeof CHAIN_CONFIG;
