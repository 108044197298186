import React,{useEffect, useState} from 'react'
import { useWeb3Auth } from "@services/web3auth";
import { useWeb3AuthWallet } from "@services/web3authWallet";
import Web3 from "web3";
import {Tooltip} from '@components/Tooltip'
import {useNavigate} from 'react-router-dom';
import { slicedAddress } from '@utils/convert';
import Title from '@components/Title'
import { CHAIN_CONFIG } from "@config/chainConfig";
import { TOKENS } from '@constants/config';
import Token from './element/Token';
import NFT from './element/Nft';
import {ABI as ABITOKEN } from '@contracts/token'
import { CurrencyDollarIcon,CollectionIcon } from '@heroicons/react/solid'
import { Tab } from '@headlessui/react'
import Modal from '@components/Modal';
import { ethers } from 'ethers';
import { EthBalance } from '@components/EthBalance';

const currentChainConfig = CHAIN_CONFIG['bsc'];

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(' ')
}

const Tabs = ({ color,tokenContracts,Account }:{color:any,tokenContracts:any,Account:string}) => {

  const [openTab, setOpenTab] = React.useState(1);
  return (
    <div className="w-full max-w-2xl px-2 py-8 sm:px-0">
      <Tab.Group>
        <Tab.List className="flex p-1 space-x-1 bg-blue-900/20 rounded-xl ">
          <Tab
              key={1}
              className={({ selected }) =>
                classNames(
                  'w-full py-2.5 text-sm leading-5 font-medium text-blue-700 rounded-lg',
                  'focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60',
                  selected
                    ? 'bg-white shadow'
                    : 'text-blue-100 hover:bg-white/[0.12] hover:text-white'
                )
              }
            >
             <div className="flex flex-row justify-center items-center">
              <CurrencyDollarIcon className="block h-6 w-6" aria-hidden="true" />
                TOKEN
             </div>
            </Tab>
            <Tab
              key={2}
              className={({ selected }) =>
                classNames(
                  'w-full py-2.5 text-sm leading-5 font-medium text-blue-700 rounded-lg',
                  'focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60',
                  selected
                    ? 'bg-white shadow'
                    : 'text-blue-100 hover:bg-white/[0.12] hover:text-white'
                )
              }
            >
              <div className="flex flex-row justify-center items-center">
                <CollectionIcon className="block h-6 w-6" aria-hidden="true" />
                Collectibles(NFT)
              </div>
            
            </Tab>
        </Tab.List>
        <Tab.Panels className="mt-4">
          <Tab.Panel
              key={1}
              className={classNames(
                'bg-white rounded-xl p-3',
                'focus:outline-none  ring-white ring-opacity-60'
              )}
            >
              <Token tokenContracts={tokenContracts} Account={Account}/>
          </Tab.Panel>
          <Tab.Panel
              key={2}
              className={classNames(
                'bg-white rounded-xl p-3',
                'focus:outline-none  ring-white ring-opacity-60'
              )}
            >
              {/* <NFT/> */}
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>

      {/* <div className="flex flex-wrap w-4/6 ">
        <div className="w-full">
          <ul
            className="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row"
            role="tablist"
          >
            <li className="-mb-px mr-2 last:mr-0 flex-auto text-center w-40">
              <a
                className={
                  "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                  (openTab === 1
                    ? "text-white bg-" + color + "-600"
                    : "text-" + color + "-600 bg-white")
                }
                onClick={e => {
                  e.preventDefault();
                  setOpenTab(1);
                }}
                data-toggle="tab"
                href="#link1"
                role="tablist"
              >
                <i className="fas fa-space-shuttle text-base mr-1"></i> Token
              </a>
            </li>
            <li className="-mb-px mr-2 last:mr-0 flex-auto text-center w-40">
              <a
                className={
                  "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                  (openTab === 2
                    ? "text-white bg-" + color + "-600"
                    : "text-" + color + "-600 bg-white")
                }
                onClick={e => {
                  e.preventDefault();
                  setOpenTab(2);
                }}
                data-toggle="tab"
                href="#link2"
                role="tablist"
              >
                <i className="fas fa-cog text-base mr-1"></i>  Collectibles(NFT)
              </a>
            </li>
          </ul>
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6">
            <div className="px-4 py-5 flex-auto">
              <div className="tab-content tab-space">
                <div className={openTab === 1 ? "block" : "hidden"} id="link1">
                  <Token tokenContracts={tokenContracts} Account={Account}/>
                </div>
                <div className={openTab === 2 ? "block" : "hidden"} id="link2">
                  <NFT/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};



const Home = () => {
  const navigate = useNavigate();

  const { provider,privateKey} = useWeb3Auth();
  const { web3Auth,WalletConnectProvider,connected} = useWeb3AuthWallet();
  const [Account,setAccount] = useState('');
  const [balance,setBlance] = useState<string>('0');
  const [tokenContracts, setTokenContracts] = useState({});

  const [openReceive,setOpenReceive]  = useState(false)

  const customHttpProvider = new ethers.providers.JsonRpcProvider('https://data-seed-prebsc-1-s2.binance.org:8545/');

  // const ss = useEthBalance(web3,privateKey);
   
  // console.log(ss);
  useEffect(() => {
  
    const initWeb3 = async ()=>{
      try {
        const web3:any =  new Web3(provider);
        let wallet = new ethers.Wallet(privateKey, customHttpProvider);
      
        // let balances = await wallet.getBalance();
        // console.log(balances);
        // customHttpProvider.getBlockNumber().then((result) => {
        //   console.log("Current block number: " + result);
        // })
   
        const account = web3.eth.accounts.privateKeyToAccount(privateKey);
   
        const balance:string = web3.utils.fromWei(
          await web3.eth.getBalance(account.address) // Balance is in wei
        );
  
        setAccount(account.address)
        setBlance(balance)
  
        getTokenContracts(TOKENS,web3)
      } catch (error) {
        console.log(error);
      }
     
    }
    if(privateKey)
      initWeb3();

    return () => {
      setBlance('')
      setAccount('')
      setTokenContracts({})
    }
  }, [privateKey]);


  useEffect(() => {

    const providers = WalletConnectProvider ?? web3Auth.provider

    const initWeb3 = async ()=>{      
      try {
   
        const web3:any = await new Web3(providers);
        const account = await web3.eth.getAccounts();
    
        const balance:string = web3.utils.fromWei(
          await web3.eth.getBalance(account[0]) // Balance is in wei
        );
  
        setAccount(account[0])
        setBlance(balance)
        getTokenContracts(TOKENS,web3)
      } catch (error) {
        console.log(error);
      }
     
    }
    if (connected)
      initWeb3();
      

    return () => {
      setBlance('')
      setAccount('')
      setTokenContracts({})
    }
  }, [connected]);

  
  
  const getTokenContract = async (symbol: any, token_address: any,web3: any) => {
      const token_abi:any = ABITOKEN
      const token = await new web3.eth.Contract(token_abi, token_address);
      return token
  }

  const getTokenContracts = async (symbols: any[],web3: any) => {
      symbols.map(async (symbol: any) => {
   
        if (symbol.symbol !== 'OM'){
          const token_address = symbol.address;
          const contract = await getTokenContract(symbol.symbol,token_address,web3)
          setTokenContracts(prev => ({...prev, [symbol.symbol]:contract}))
        }
        
      })
  }

  const toTranfer = () => {
    navigate('/transfer', {replace: true});
  };

  if (!connected){
    if (!privateKey) return <div/>
  }
  
  return (
    <main>
      <div className="max-w-7xl mx-auto py-2 sm:px-6 lg:px-8">
          {/* header */}
          <Title accout={Account} title={'Account Balance'}/>
          {/* card */}
          <div className="p-4 w-full  bg-white rounded-lg border shadow-md sm:p-6 ">
            <div className='flex flex-row'>
              <div className='basis-1/2'>
                <h1 className='mb-2 text-1xl font-bold tracking-tight text-gray-900 text-white'>TOTAL VALUE</h1>
                <div className='flex relative w-25 flex-row'>
                  <div className='flex'>
                    {connected? <p className='text-4xl font-bold text-gray-500'>{ balance ? parseFloat(balance).toFixed(3) : 0}</p>
                    :   
                    <EthBalance privateKey={privateKey}/>
                    }
                 
                   
                    <small className='justify-end items-end flex'>{currentChainConfig.tickerName}</small>
                  </div>
                </div>
              </div>
              <div className='basis-1/2 text-right'>
                <small className="inline-flex items-center justify-center px-2 py-1 text-xs hover:text-xs font-medium  text-white bg-red-500 rounded">{currentChainConfig.displayName}</small>
              </div>
            </div>
            <div className='flex flex-row'>
              <div className='flex w-96 mt-4 mx-2'>
                <button onClick={toTranfer} className="h-10 w-full max-w-md px-5 text-blue-700 transition-colors duration-150 border border-blue-500 rounded-lg focus:shadow-outline hover:bg-blue-500 hover:text-blue-100">Transfer</button>
              </div>
              <div className='flex  w-96 mt-4  mx-2'>
                <Modal account={Account} setActive={setOpenReceive} openReceive={openReceive}/>
                <button onClick={()=>setOpenReceive(true)} className="h-10 w-full max-w-md px-5 text-red-700 transition-colors duration-150 border border-red-500 rounded-lg focus:shadow-outline hover:bg-red-500 hover:text-red-100">Receive</button>
              </div>
            </div>
          </div>

          {/* tabs */}
          <div className="flex justify-center w-full">
            <Tabs color="red" tokenContracts={tokenContracts} Account={Account} />
          </div>
      </div>
    </main>
  

  )
}

export default Home;