import React,{useEffect,useState} from 'react'
import { useWeb3Auth } from "@services/web3auth";
import {ABI as ABITOKEN } from '@contracts/token'
import {useNavigate} from 'react-router-dom';
import Web3 from "web3";
import { TOKENS } from '@constants/config';
import {TokenBalance} from '@components/TokenBalance';
const ccoin:any = require('@assets/icon_ccoin.png');


export default function Token({ tokenContracts,Account }:{tokenContracts:any,Account:string}) {

    const navigate = useNavigate();
    const [tokenSymbols, setTokenSymbols] = useState<any>([]);

    
    useEffect(() => {
        if(tokenContracts){
            getTokenBalances()
        }
        return () => {setTokenSymbols({})}
    }, [tokenContracts]);

    const getTokenBalances = () => {
        TOKENS.map(async symbol => {
            if (symbol.address === '') return false;
            setTokenSymbols((prev: any) => ({...prev, [symbol.symbol]: 
                {
                    name:symbol.name,
                    icon:symbol.avatar,
                    address:symbol.address
                } 
            }))
        })
    }

  
    return (
        <div className="grid md:grid-cols-2 md:gap-2 sm:grid-cols-1 sm:gap-1 justify-items-start">
            {Object.keys(tokenSymbols).map((symbol:any, idx) => (
                <div onClick={()=> navigate(`/transfer?token=${tokenSymbols[symbol.toUpperCase()].address}`, {replace: true}) } className="flex flex-row bg-white hover:bg-gray-100 hover:border-gray-200 rounded-lg w-full items-center  shadow-md py-6 px-4 my-2 cursor-pointer" key={idx}>
                    <div className="flex-initial w-1/2 flex flex-row items-center">
                        <img src={tokenSymbols[symbol.toUpperCase()].icon} alt="ccoin" width="30%" />
                        <div className="ml-2 flex-initial w-1/2">{tokenSymbols[symbol.toUpperCase()].name}</div>
                    </div>
          
                    <TokenBalance  address={tokenSymbols[symbol.toUpperCase()].address} decimals={18} account={Account} className='flex-initial w-1/2 text-right align-middle'/>
                    {symbol}
                </div>
            ))}
        
        </div>
    )
    
   
}
