import React from "react"
import Header from "./Header"
// import Footer from "./Footer"


class Layout extends React.Component {
  
  render(){
    return (
      <>
       <div className="min-h-full">
          <Header />
          <div className="px-2">
          {this.props.children}
          </div>
          
          {/* <Footer /> */}
        </div>
      </>
    )
  }
}
export default Layout;