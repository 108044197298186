import React,{Fragment,useEffect, useState} from 'react'
import { useWeb3Auth } from "@services/web3auth";
import { useWeb3AuthWallet } from "@services/web3authWallet";
import Web3 from "web3";
// import {useNavigate} from 'react-router-dom';
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import axios from 'axios'

import List from './elements/List';

const transaction = [
    {
      id: 1,
      name: 'All Transactions',
    },
    {
      id: 2,
      name: 'Send',
    },
    {
        id: 3,
        name: 'Receive',
    }
]

const dates = [
    {
      id: 1,
      name: 'All',
    },
    {
      id: 2,
      name: 'Last 1 week',
    },
    {
        id: 3,
        name: 'Last 1 month',
    }
]
  

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
  }
  
const Activity = () => {
  const { provider,privateKey } = useWeb3Auth();
  const {web3Auth,WalletConnectProvider,connected} = useWeb3AuthWallet();

  const [Account,setAccount] = useState('');

  const [selectedTrasaction, setSelectedTransaction] = useState(transaction[0])
  const [selectedDate, setSelectedDate] = useState(dates[0])
  const [dataTransaction, setDataTransaction] = useState([])

  
  
  useEffect(() => {
    const getAccout = async ()=>{
        try {
            const web3 = new Web3(provider);
            const account = web3.eth.accounts.privateKeyToAccount(privateKey);
            setAccount(account.address)
        } catch (error) {
            console.log(error)
        }
    
    }
    if (privateKey)
        getAccout();
    return () => {}
  }, [privateKey, provider]);

  //web3Wallet
  useEffect(() => {
    const getAccout = async ()=>{
        const providers = WalletConnectProvider ?? web3Auth.provider 
        try {
            const web3 = new Web3(providers);
            const account = await web3.eth.getAccounts();
            setAccount(account[0])
        } catch (error) {
            console.log(error)
        }
    
    }
    if (connected)
        getAccout();
    return () => {}
  }, [connected]);

  useEffect(() => {
      
    const getTransaction = async()=>{
        let filter = '';
        if (selectedTrasaction.id == 2){
            filter = 'from'
        }else if (selectedTrasaction.id == 3){
            filter = 'to'
        }
        
        await axios
        .get(
        //   ` https://evm-explorer.omplatform.com/api?module=account&action=txlist&address=${Account}&filterby=${filter}`
        `https://api-testnet.bscscan.com/api?module=account&action=txlist&address=${Account}&startblock=1&endblock=99999999&sort=asc&apikey=AWUJI9GMP8Q7KKBGA7TJU7AUEI8UDWIY9N`
        )
        .then((response) => {
            const res = response.data;

            if (res.status === '1'){
                setDataTransaction(res.result)
            }
        })
        .catch((error) => {
          console.error(error)
        })
       
    }
  
    getTransaction()
  }, [Account,selectedTrasaction])
  
  return (
    <main>
        <div className="max-w-7xl mx-auto py-6 lg:px-8 ">
            {/* header */}
            <div className='md:flex flex-row py-4 px-2'>
                <div className='flex w-full lg:w-1/2'>
                    <h1 className='mb-2 text-2xl font-bold tracking-tight text-gray-500  sm:text-center '>Transaction Activities</h1>
                </div>
                {/* <div className='flex w-full lg:w-1/2 justify-end px-4'>
                    <div className='flex-row flex mx-auto w-80'>
                        <Listbox value={selectedTrasaction} onChange={setSelectedTransaction}>
                            {({ open }) => (
                                <>
                                <div className="mt-1 relative  mr-4">
                                    <Listbox.Button className="relative md:w-44 bg-white border border-gray-300 rounded-md shadow-sm pr-10 py-2 text-left cursor-default focus:outline-none  sm:text-sm">
                                    <span className="flex items-center">
                                        <span className="ml-3 block truncate">{selectedTrasaction.name}</span>
                                    </span>
                                    <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                        <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                    </span>
                                    </Listbox.Button>

                                    <Transition
                                    show={open}
                                    as={Fragment}
                                    leave="transition ease-in duration-100"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                    >
                                    <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                        {transaction.map((item) => (
                                        <Listbox.Option
                                            key={item.id}
                                            className={({ active }) =>
                                            classNames(
                                                active ? 'text-white bg-indigo-600' : 'text-gray-900',
                                                'cursor-default select-none relative py-2 pl-3 pr-9'
                                            )
                                            }
                                            value={item}
                                        >
                                            {({ selected, active }) => (
                                            <>
                                                <div className="flex items-center">
                                                <span
                                                    className={classNames(selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate')}
                                                >
                                                    {item.name}
                                                </span>
                                                </div>

                                                {selected ? (
                                                <span
                                                    className={classNames(
                                                    active ? 'text-white' : 'text-indigo-600',
                                                    'absolute inset-y-0 right-0 flex items-center pr-4'
                                                    )}
                                                >
                                                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                </span>
                                                ) : null}
                                            </>
                                            )}
                                        </Listbox.Option>
                                        ))}
                                    </Listbox.Options>
                                    </Transition>
                                </div>
                                </>
                            )}
                        </Listbox>

                        <Listbox value={selectedDate} onChange={setSelectedDate}>
                            {({ open }) => (
                                <>
                                <div className="mt-1 relative">
                                    <Listbox.Button className="relative w-40 bg-white border border-gray-300 rounded-md shadow-sm pr-10 py-2 text-left cursor-default focus:outline-none  sm:text-sm">
                                    <span className="flex items-center">
                                        <span className="ml-3 block truncate">{selectedDate.name}</span>
                                    </span>
                                    <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                        <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                    </span>
                                    </Listbox.Button>

                                    <Transition
                                    show={open}
                                    as={Fragment}
                                    leave="transition ease-in duration-100"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                    >
                                    <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                        {dates.map((item) => (
                                        <Listbox.Option
                                            key={item.id}
                                            className={({ active }) =>
                                            classNames(
                                                active ? 'text-white bg-indigo-600' : 'text-gray-900',
                                                'cursor-default select-none relative py-2 pl-3 pr-9'
                                            )
                                            }
                                            value={item}
                                        >
                                            {({ selected, active }) => (
                                            <>
                                                <div className="flex items-center">
                                                <span
                                                    className={classNames(selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate')}
                                                >
                                                    {item.name}
                                                </span>
                                                </div>

                                                {selected ? (
                                                <span
                                                    className={classNames(
                                                    active ? 'text-white' : 'text-indigo-600',
                                                    'absolute inset-y-0 right-0 flex items-center pr-4'
                                                    )}
                                                >
                                                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                </span>
                                                ) : null}
                                            </>
                                            )}
                                        </Listbox.Option>
                                        ))}
                                    </Listbox.Options>
                                    </Transition>
                                </div>
                                </>
                            )}
                        </Listbox>
                    </div>
                    
                </div> */}
            </div>
            {/* card */}
            <div className="p-4 w-full  bg-white">
                <div className='flex flex-row justify-center'>
                {dataTransaction.length > 0 ?
                     <div className="grid grid-flow-row auto-rows-max w-full">
                         {dataTransaction.map((item:any, index) => (
                             <List account={Account} item={item} index={index}/>
                        ))}
                    </div>
                    :
                    <div className="text-center text-gray-500">
                        No data available
                    </div>
                }
  
                 
                </div>
            </div>

        
        </div>
    </main>

  )
}

export default Activity;