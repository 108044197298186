import {ethers} from "ethers";
import { formatEther, formatUnits } from '@ethersproject/units'
import useEtherSWR, { EthSWRConfig } from 'ether-swr'

export const EthBalance = ({privateKey}:any) => {
  const customHttpProvider = new ethers.providers.JsonRpcProvider('https://rpc.omplatform.com/');
  let wallet:any = new ethers.Wallet(privateKey, customHttpProvider);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { data: balance } = useEtherSWR(['getBalance', wallet.address, 'latest'])
  if (!balance) {
    return <div className='text-4xl font-bold text-gray-500'>0</div>
  }
  return <div className='text-4xl font-bold text-gray-500'>{parseFloat(formatEther(balance)).toFixed(3)} </div>
}