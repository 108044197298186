/* eslint-disable react-hooks/rules-of-hooks */
import React,{ useState } from 'react'
import {Tooltip} from '@components/Tooltip'
import { slicedAddress } from '@utils/convert';
import { QrcodeIcon } from '@heroicons/react/outline'

import Modal from '../Modal'
function index(props: any) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [copySuccess, setCopySuccess] = useState<string>('copy address');
    const [showModal, setShowModal] = useState<boolean>(false);
    const [openReceive,setOpenReceive]  = useState(false)
    const account = props.accout
    const title = props.title

    const  copyToClipboard = () =>{
        navigator.clipboard.writeText(account)
        setCopySuccess('Copied!');
    };
 
    return (
        <header className="bg-white">
          <div className=" mx-auto py-2">
             <div className='md:flex flex-row py-2'>
                 <div className='basis-2/3'>
                     <h1 className='mb-2 text-2xl font-bold tracking-tight text-gray-500 '>{title}</h1>
                 </div>
                 <div className='basis-1/3 flex justify-end'>
                     <p className='text-xs px-2 py-1 text-grey-800'>Account ID</p>
                     <Tooltip message={copySuccess}>
                         <button className='px-2 rounded-full bg-gray-100' onClick={copyToClipboard} >
                            <span className='text-xs font-medium text-gray-500 '>{slicedAddress(account)}</span>
                         </button>
                     </Tooltip>
                     <Modal account={account} setActive={setOpenReceive} openReceive={openReceive}/>
                    <div onClick={()=>setOpenReceive(true)} className="ml-2 cursor-pointer">
                        <QrcodeIcon className="h-6 w-6 text-gray-500" aria-hidden="true" />
                    </div>
                 </div>

             </div>
          </div>
         
        </header>
        // <div className='flex flex-row py-4'>
        //     <div className='basis-2/3'>
        //         <h1 className='mb-2 text-2xl font-bold tracking-tight text-gray-900 '>{title}</h1>
        //     </div>
        //     <div className='basis-1/3 flex justify-end'>
        //         <Tooltip message={copySuccess}>
        //             <button className='px-2 rounded bg-gray-200' onClick={copyToClipboard} >
        //             <span className='text-sm font-bold '>{slicedAddress(account)}</span>
        //             </button>
        //         </Tooltip>
        //     </div>
        // </div>
    )
}

export default index