import React,{useState,useEffect} from 'react'
import { useNavigate,useLocation } from "react-router-dom"; 
import { useWeb3Auth } from "@services/web3auth";
import { useWeb3AuthWallet } from "@services/web3authWallet";
import Web3 from "web3";
//load token
import {ABI, ADDRESS} from '@contracts/groupBy'

function Comfirmed() {

  const value:any = useLocation();

  const {res,profile,amount}  = value.state.data;
  
  return (
    <main>
      <div className="max-w-7xl mx-auto  sm:px-6 lg:px-8 sm:px-4">
        <div className="relative bg-white overflow-hidden">
          <div className="pt-6">
            <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 sm:static">
            <div className="flex flex-col">
                {/* Decorative image grid */}
                  <div
                    aria-hidden="true"
                    className="pointer-events-none lg:inset-y-0 lg:max-w-6xl lg:w-full"
                  >
                    <div className="md:absolute transform sm:left-1/2 sm:top-0 sm:translate-x-8  lg:top-1/2 lg:-translate-y-1/2 lg:translate-x-8">
                        <h1 className="text-4xl font font-extrabold tracking-tight text-gray-900 sm:text-5xl">
                          ขอบคุณสำหรับการร่วมแคมเปญ
                      </h1>
                      <div className="flex items-center grid grid-rows-3 grid-flow-col flex-wrap w-full">
                          <h3 className="text-xl text-gray-500">ขั้นตอนการร่วมแคมเปญ</h3>
                          <ul>
                              <li>หลังจากแคมเปญถึงเป้าหมายที่กำหนด เจ้าของแคมเปญ จะยืนยันการสั่งจอง</li>
                              <li>หลังจากยืนยันจะส่งข้อมูลไป อีเมลของท่านเพื่อทำการยืนยันช้อมูลตามที่ระบุไว้</li>
                              <li>เจ้าของแคมเปญจะจัดส่งสินค้าตามที่อยู่</li>
                              <li>ตรวจสอบข้อมูลการรับได้สินค้า</li>
                          </ul>
                      </div>
                    </div>
                  </div>
           
                <a
                  href="/dapp"
                  className="my-4 inline-block text-center bg-blue-600 border border-transparent rounded-md py-3 px-8 font-medium text-white hover:bg-blue-700 w-44"
                >
                กลับหน้าแรก
                </a>
              </div>
              <div className="sm:max-w-lg">
                
                  <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                      <div className="px-4 py-5 sm:px-6">
                          <h3 className="text-lg leading-6 font-medium text-gray-900">ยืนยันคำสั่งซื้อ</h3>
                          {/* <p className="mt-1 max-w-2xl text-sm text-gray-500">Personal details and application.</p> */}
                      </div>
                      <div className="border-t border-gray-200">
                        <dl>
                          <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                              <dt className="text-sm font-medium text-gray-500">เลขสั่งซื้อ</dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 break-words">{res.transactionHash}</dd>
                          </div>
                          <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                              <dt className="text-sm font-medium text-gray-500">From Address</dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 break-words">{res.from}</dd>
                          </div>
                          <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                              <dt className="text-sm font-medium text-gray-500">To Address</dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 break-words">{res.to}</dd>
                          </div>
                          <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                              <dt className="text-sm font-medium text-gray-500">Gas Used</dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 break-words">{res.gasUsed}</dd>
                          </div>
                          {/* <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                              <dt className="text-sm font-medium text-gray-500">Email address</dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">pengjankul@gmail.com</dd>
                          </div> */}
                          {/* <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                              <dt className="text-sm font-medium text-gray-500">เป็นเงิน</dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2"> {products.user_order * 795} บาท</dd>
                          </div> */}
                          <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                              <dt className="text-sm font-medium text-gray-500">จำนวนที่สั่ง</dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                  {amount}
                              </dd>
                          </div>
                          
                          <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                              <dt className="text-sm font-medium text-gray-500">ที่อยู่จัดส่ง</dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {`${profile.ContactName} ${profile.Address} ${profile.ContactTel}`}

                              </dd>
                          </div>
                          
                        </dl>
                    </div>
                  </div>

                  </div>
              <div>
             
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default Comfirmed