import { eipsType } from './../types'

export const EIPs: eipsType = {
  1559: require('./1559.json'),
  2315: require('./2315.json'),
  2537: require('./2537.json'),
  2565: require('./2565.json'),
  2718: require('./2718.json'),
  2929: require('./2929.json'),
  2930: require('./2930.json'),
  3198: require('./3198.json'),
  3529: require('./3529.json'),
  3540: require('./3540.json'),
  3541: require('./3541.json'),
  3554: require('./3554.json'),
  3607: require('./3607.json'),
  3670: require('./3670.json'),
  3675: require('./3675.json'),
  3855: require('./3855.json'),
  3860: require('./3860.json'),
  4345: require('./4345.json'),
  4399: require('./4399.json'),
}
