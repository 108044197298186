/* eslint-disable max-len */
import { ADAPTER_EVENTS, CHAIN_NAMESPACES, SafeEventEmitterProvider, WALLET_ADAPTER_TYPE } from "@web3auth/base";
import type { LOGIN_PROVIDER_TYPE } from "@toruslabs/openlogin";

import { Web3AuthCore } from "@web3auth/core";
import { Web3Auth } from "@web3auth/web3auth";
import { OpenloginAdapter } from "@web3auth/openlogin-adapter";
import { createContext, FunctionComponent, ReactNode, useCallback, useContext, useEffect, useState } from "react";
import { CHAIN_CONFIG, CHAIN_CONFIG_TYPE } from "@config/chainConfig";
import { WEB3AUTH_NETWORK_TYPE } from "@config/web3AuthNetwork";
import { getWalletProvider, IWalletProvider } from "./walletProvider";
import { MetamaskAdapter } from "@web3auth/metamask-adapter";
import { WalletConnectV1Adapter } from "@web3auth/wallet-connect-v1-adapter";
import { EthereumPrivateKeyProvider } from "@web3auth/ethereum-provider";
import { NetworkSwitch } from "@web3auth/ui"
import QRCodeModal from "@walletconnect/qrcode-modal";
import WalletConnectProvider from "@walletconnect/web3-provider";

export interface IWeb3AuthContext {
  web3Auth: Web3AuthCore | null | any;
  provider: any;
  WalletConnectProvider:any;
  privateKey: any;
  isWallet: boolean;
  connected: boolean;
  user: unknown;
  loginWallet: (adapter: WALLET_ADAPTER_TYPE,provider: LOGIN_PROVIDER_TYPE, login_hint?: string) => Promise<void>;
  loginWithWalletConnect: (adapter: WALLET_ADAPTER_TYPE)=> Promise<void>;
  logoutWallet: () => Promise<void>;
  getUserWallet: (adapter: WALLET_ADAPTER_TYPE) => Promise<any>;
  signMessage: () => Promise<any>;
  getAccounts: () => Promise<any>;
  getBalance: () => Promise<any>;
}

export const Web3AuthContextWallet = createContext<IWeb3AuthContext>({
  web3Auth: null,
  provider: null,
  WalletConnectProvider:null,
  privateKey: null,
  isWallet: false,
  connected: false,
  user: null,
  loginWallet: async (adapter: WALLET_ADAPTER_TYPE, provider?: LOGIN_PROVIDER_TYPE, login_hint?: string) => {},
  loginWithWalletConnect: async (adapter: WALLET_ADAPTER_TYPE)=> {},
  logoutWallet: async () => {},
  getUserWallet: async (adapter: WALLET_ADAPTER_TYPE) => {},
  signMessage: async () => {},
  getAccounts: async () => {},
  getBalance: async () => {},
});

export function useWeb3AuthWallet() {
  return useContext(Web3AuthContextWallet);
}

interface IWeb3AuthState {
  web3AuthNetwork: WEB3AUTH_NETWORK_TYPE;
  chain: CHAIN_CONFIG_TYPE;
}
interface IWeb3AuthProps {
  children?: ReactNode;
  web3AuthNetwork: WEB3AUTH_NETWORK_TYPE;
  chain: CHAIN_CONFIG_TYPE;
}


//  Create WalletConnect Provider
const WalletConnectprovider:any = new WalletConnectProvider({
  rpc: {
    1246: "https://rpc.omplatform.com",
  },
});

export const Web3AuthProviderWallet: FunctionComponent<IWeb3AuthState> = ({ children, web3AuthNetwork, chain }: IWeb3AuthProps) => {
  const [web3Auth, setWeb3Auth] = useState<Web3AuthCore | null>(null);
  const [provider, setProvider] = useState<any>(null);
  const [WalletConnectProvider, setWalletConnectProvider] = useState<any>(null);
  const [privateKey, setPrivateKey] = useState<any>(null);
  const [user, setUser] = useState<unknown | null>(null);
  const [isWallet, setIsWallet] = useState(false);

  const [connected, setConnected] = useState(false);

  

  const setWalletProvider = useCallback(
    (web3authProvider: SafeEventEmitterProvider) => {
      const walletProvider = getWalletProvider(chain, web3authProvider, uiConsole);
      setProvider(walletProvider);
    },
    [chain]
  );

  useEffect(() => {
    const subscribeAuthEvents = async (web3auth: Web3AuthCore) => {
      // Can subscribe to all ADAPTER_EVENTS and LOGIN_MODAL_EVENTS
      web3auth.on(ADAPTER_EVENTS.CONNECTED, async (data: unknown) => {
        // console.log("Yeah!, you are successfully logged in", data);
        setUser(data);
        setConnected(true)
        setWalletProvider(web3auth.provider!);
 
      });

      web3auth.on(ADAPTER_EVENTS.CONNECTING, () => {
        console.log("connecting");
        setConnected(false)
      });

      web3auth.on(ADAPTER_EVENTS.DISCONNECTED, () => {
        console.log("disconnected");
        setUser(null);
        setConnected(false)

      });

      web3auth.on(ADAPTER_EVENTS.ERRORED, (error: unknown) => {
        console.error("some error or user has cancelled login request", error);
      });

      //check wallet connect login
        // WalletConnectprovider.on("accountsChanged", (accounts: string[]) => {
        //   localStorage.setItem('connectedWalletConnect', 'true')
        // });

        // // Subscribe to chainId change
        // WalletConnectprovider.on("chainChanged", (chainId: number) => {
        //   localStorage.setItem('connectedWalletConnect', 'true')
        // });

        // // Subscribe to session disconnection
        // WalletConnectprovider.on("disconnect", (code: number, reason: string) => {
        //   localStorage.setItem('connectedWalletConnect', 'false')
        // });

        // if (localStorage.getItem('connectedWalletConnect') === 'true'){
          
        //   await loginWithWalletConnect()
        //   setConnected(true)
          
        // }else{
        //   setConnected(false)
        // }
 
    };

    const currentChainConfig:any = CHAIN_CONFIG[chain];
    
    async function init() {
      try {
        setIsWallet(true);
        const config:any = {
          chainNamespace: CHAIN_NAMESPACES.EIP155,
          rpcTarget: "https://rpc.omplatform.com/",
          blockExplorer: "https://evm-explorer.omplatform.com/",
          chainId: "0x4DE",
          displayName: "Central Chain Mainnet",
          ticker: "ETH",
          tickerName: "Ethereum",
        }
      
        const clientId = "BA4jrb8ITCdUl0pVT-FesR5DT1IZc_mH3oC-NSKF2w27o8tYv9bVfL2BYDMjsbjmDkWq53uWIVYZ-XfxKGkSVIc";
        const web3AuthInstance = new Web3AuthCore({
          chainConfig: currentChainConfig,
          // enableLogging: true
          // chainConfig: { chainNamespace: 'other' },
          // clientId: clientId
        });

        
        subscribeAuthEvents(web3AuthInstance);

        // const adapter = new OpenloginAdapter({ adapterSettings: { network: web3AuthNetwork, clientId, uxMode: 'redirect'} });
        // web3AuthInstance.configureAdapter(adapter);
     
        const metamaskAdapter = new MetamaskAdapter(currentChainConfig);
        web3AuthInstance.configureAdapter(metamaskAdapter);

        const networkUi = new NetworkSwitch()
        const wcAdapter = new WalletConnectV1Adapter({ adapterSettings: { qrcodeModal: QRCodeModal, networkSwitchModal: networkUi }, chainConfig: currentChainConfig  })
        web3AuthInstance.configureAdapter(wcAdapter);
  
        await web3AuthInstance.init();
        setWeb3Auth(web3AuthInstance);
      
      } catch (error) {
        console.error(error);
      } finally {
        setIsWallet(false);
      }
    }

    init();

  }, [chain, web3AuthNetwork, setWalletProvider]);


  const loginWallet = async (adapter: WALLET_ADAPTER_TYPE, loginProvider: LOGIN_PROVIDER_TYPE|null, login_hint?: string) => {

    try {
      setIsWallet(true);
      if (!web3Auth) {
        console.log("web3auth not initialized yet");
        uiConsole("web3auth not initialized yet");
        return;
      }
      const localProvider = await web3Auth.connectTo(adapter, { loginProvider, login_hint });
      setWalletProvider(localProvider!);
      // setProvider('https://rpc.omplatform.com/');

      setConnected(true)
     
    } catch (error:any) {
      console.log("error", error);
      // alert('Not Detect!! please install Metamark')
      alert(error.message)
    } finally {
      setIsWallet(false)
    }
  };

  const loginWithWalletConnect = async (adapter: WALLET_ADAPTER_TYPE) => {
    try {
      setIsWallet(true);
      if (!web3Auth) {
        console.log("web3auth not initialized yet");
        uiConsole("web3auth not initialized yet");
        return;
      }
      const localProvider = await web3Auth.connectTo(adapter, {});
      // console.log(localProvider);
      
      // const localProvider:any = await WalletConnectprovider.enable();
     
      setWalletProvider(localProvider!);
      // setWalletConnectProvider(WalletConnectprovider!)
      setConnected(true)

    } catch (error:any) {
      console.log("error", error);
      // alert(error.message)
    } finally {
      setIsWallet(false)
    }
  };


  const logoutWallet = async () => {
    if (!web3Auth) {
      console.log("web3auth not initialized yet");
      uiConsole("web3auth not initialized yet");
      return;
    }

    //wallet connect
    if(localStorage.getItem('connectedWalletConnect') === 'true'){
      localStorage.removeItem("isWalletConnected");
      localStorage.removeItem("connectedWalletConnect");
    }else{
      await web3Auth.logout();
      localStorage.removeItem("isWalletConnected");
      localStorage.removeItem("connectedWalletConnect");
    }


    setProvider(null);
    setPrivateKey(null)
    setConnected(false)
    window.location.href = "/";
  };

  const getUserWallet = async (adpter:any) => {

    if (!web3Auth) {
      console.log("web3auth not initialized yet");
      // uiConsole("web3auth not initialized yet");
      return;
    }
    const user = {
      name:`${adpter}`,
      email:'-'
    }
    // const user = await web3Auth.getUserInfo();
    return user;
  };

  const getAccounts = async () => {

    
    if (!provider) {
      console.log("provider not initialized yet");
      uiConsole("provider not initialized yet");

      return;
    }
    provider.getAccounts()
  };

  const getBalance = async () => {
    if (!provider) {
      console.log("provider not initialized yet");
      uiConsole("provider not initialized yet");
      return;
    }
    provider.getBalance();
  };

  const signMessage = async () => {
    if (!provider) {
      console.log("provider not initialized yet");
      uiConsole("provider not initialized yet");
      return;
    }
    provider.signMessage();
  };

  const uiConsole = (...args: unknown[]): void => {
    // const el = document.querySelector("#console>p");
    // if (el) {
    //   el.innerHTML = JSON.stringify(args || {}, null, 2);
    // }
  };

  const contextProvider = {
    web3Auth,
    provider,
    WalletConnectProvider,
    privateKey,
    user,
    connected,
    isWallet,
    loginWallet,
    logoutWallet,
    getUserWallet,
    loginWithWalletConnect,
    getAccounts,
    getBalance,
    signMessage,
  };
  return <Web3AuthContextWallet.Provider value={contextProvider}>{children}</Web3AuthContextWallet.Provider>;
};



