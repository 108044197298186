
export const CONFIG = {
    symbol: 'CCOIN',
};

export const TOKENS = [
    {
        id: 0,
        symbol: 'BNB',
        address: '',
        name: 'BNB',
        avatar:require('@assets/eth.png')
    },
    {
        id: 1,
        symbol: 'USDT',
        // address: '0x66E70704c67f3e7242387083B0C3BC3a5E1879F6',
        // address: '0xC2f3F6eF12D5271d78adB0E917d0e48b80738f9f',
        address: '0x337610d27c682E347C9cD60BD4b3b107C9d34dDd',
        name: 'USDT',
        avatar:require('@assets/icon-om.png')
    },
    // {
    //     id: 1,
    //     symbol: 'WOM',
    //     // address: '0x66E70704c67f3e7242387083B0C3BC3a5E1879F6',
    //     // address: '0xC2f3F6eF12D5271d78adB0E917d0e48b80738f9f',
    //     address: '0xf1ea1E72616e285C4d8c229E0f95aEfFEf35F641',
    //     name: 'WOM',
    //     avatar:require('@assets/icon-om.png')
    // },
    // {
    //     id: 2,
    //     symbol: 'CCC',
    //     address: '0x8CedC9DfBb02eacbD53A9dFac52124a47b23d6A2',
    //     name: 'ChiangMaiCryptoCity',
    //     avatar:require('@assets/CCC.png')
    // },

    
];