/* eslint-disable react-hooks/rules-of-hooks */
import React,{useState,useEffect} from 'react'
import Title from '@components/Title'
import { useWeb3Auth } from "@services/web3auth";
import { useWeb3AuthWallet } from "@services/web3authWallet";
import Web3 from "web3";
import axios from 'axios';
import Swal from 'sweetalert2'
import { Navigate, useNavigate,useParams,useSearchParams } from 'react-router-dom';
import Loader from '@components/Loader';

const index = () => {
    const navigate = useNavigate();
    let { WalletAddress } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const email_activation = searchParams.get("email_activation")
    
    const { provider,privateKey,getUserInfo} = useWeb3Auth();
    const { web3Auth,WalletConnectProvider,connected} = useWeb3AuthWallet();
  
    const [Account,setAccount] = useState('');
    const [balance,setBlance] = useState<string>('0');
    
    const [profile,setProfile] = useState<any>({});
    const [vertified,setVerified] = useState<any>('NONE');

    const [dataUser, setDataUser] = useState(
        { 
            WalletAddress: "", 
            Address: "",
            FirstName:"",
            LastName:"",
            Tel:"",
            Email:"",
            ContactName:"",
            Zipcode:"",
            ContactTel:""
        }
    );

    const [dataError, setDataError] = useState(
        { 
            Address: "",
            ContactName:"",
            Zipcode:"",
            ContactTel:""
        }
    );

    useEffect(() => {
    
      const initWeb3 = async ()=>{
        try {
     
            const web3:any = await new Web3(provider);
            const account = web3.eth.accounts.privateKeyToAccount(privateKey);
        
            const balance:string = web3.utils.fromWei(
                await web3.eth.getBalance(account.address) // Balance is in wei
            );
            
            setAccount(account.address)
          
            const users = await getUserInfo();
            const name = users.name.split(' ');

            setDataUser((prevState: any) => ({
                ...prevState,
                'WalletAddress': account.address,
                'Email': users.email,
                'FirstName': name[0],
                'LastName': name[1],
            }));
            
            
        } catch (error) {
          console.log(error);
        }
       
      }
      if(privateKey)
        initWeb3();
  
      return () => {
        setBlance('')
        setAccount('')
      }
    }, [privateKey]);
  
  
    useEffect(() => {
    
        const initWeb3 = async ()=>{     
            const providers = WalletConnectProvider ?? web3Auth.provider 
            try {
                const web3:any = await new Web3(providers);
                const account = await web3.eth.getAccounts();
                
                const balance:string = web3.utils.fromWei(
                await web3.eth.getBalance(account[0]) // Balance is in wei
                );

                setAccount(account[0])
                setBlance(balance)
                
                setDataUser((prevState: any) => ({
                    ...prevState,
                    'WalletAddress': account[0]
                }));

            } catch (error) {
                console.log(error);
            }
        }
        
        if (connected)
        initWeb3();
        
  
      return () => {
        setBlance('')
        setAccount('')
      }
    }, [connected]);

    //load profile
    useEffect(() => {
        const getProfile = async ()=>{
            try {

                await axios.post(`https://cnx-groupby-demo.leafbot.io/api/demo/account/${WalletAddress}`, { action: 'info'}).then((res)=>{
                    const data = res.data;
                    if (data.data){
                        const val = data.data;
                        
                        const input =  { 
                            WalletAddress: val.walletAddress, 
                            Address: val.shippingAddress,
                            FirstName:val.firstName,
                            LastName:val.lastName,
                            Tel:val.tel,
                            Email:val.email,
                            ContactName:val.contractName,
                            Zipcode:val.zipCode,
                            ContactTel:val.contactTel
                        }
                        if(val.emailStatus){
                            setVerified(val.emailStatus)
                        }
                       
                        setDataUser(input);
                    }
                })
                // await axios.get(`https://api.airtable.com/v0/appUuOWAHjGFOgM4M/tblRsGCholGSf1fR7?filterByFormula=SEARCH("${Account}",WalletAddress)`,{
                // headers: {
                //     'Content-Type': 'application/json;charset=UTF-8',
                //     'accept': 'application/json',
                //     'Authorization': `Bearer keyXS39pJOlAd3YW3`
    
                // }}).then((res)=>{
                //     const data = res.data.records[0];
                    
                //     setDataUser(data.fields);
                //     setProfile(data)
                // })
            } catch (error) {
                console.log(error);
                
            }
        }

        const getActive = async ()=>{
            if (email_activation !== 'FAILED'){
                await Swal.fire({
                    title: 'Message',
                    text: 'email verified successfully',
                    icon: 'success',
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'ok'
                })
                navigate('/dapp')
            }
        }
    
        if (Account) getProfile();
        if (email_activation) getActive()
    }, [Account])
    

    const handleChange = (e: { target: { name: any; value: any; }; }) => {
        const { name, value } = e.target;
        setDataUser((prevState: any) => ({
            ...prevState,
            [name]: value
        }));

        setDataError((prevState: any) => ({
            ...prevState,
            [name]: ""
        }));
    };

    const submit = async()=>{
        let data = {}
 
        if (!validate() && !Account){
            return false;
        }

        data ={
            "action": 'update',
            "payload": {
                "walletAddress": Account,
                "firstName": dataUser.FirstName.trim(),
                "lastName": dataUser.LastName.trim(),
                "tel": dataUser.Tel,
                "email": dataUser.Email.trim(),
                "shippingAddress": dataUser.Address.trim(),
                "contractName": dataUser.ContactName.trim(),
                "zipCode": dataUser.Zipcode,
                "contactTel": dataUser.ContactTel,
                "props": {}
            }
            // "payload": {
            //     "walletAddress": '0x70Ec04C8914FF6543f0Ee98a30B083214AAab445',
            //     "firstName": dataUser.FirstName.trim(),
            //     "lastName": dataUser.LastName.trim(),
            //     "tel": dataUser.Tel,
            //     "email": 'sugusza@gmail.com',
            //     "shippingAddress": dataUser.Address.trim(),
            //     "contractName": dataUser.ContactName.trim(),
            //     "zipCode": dataUser.Zipcode,
            //     "contactTel": dataUser.ContactTel,
            //     "props": {}
            // }
        }
        // try {
            await axios.post(`https://cnx-groupby-demo.leafbot.io/api/demo/account/${WalletAddress}`,data,{
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                // 'accept': 'application/json',
                'Authorization': `Bearer keyXS39pJOlAd3YW3`

            }}).then(async (res)=>{
                
                if (vertified === 'NONE'){
                    await Swal.fire({
                        title: 'Message',
                        text: 'Please check verified This Email Address',
                        icon: 'success',
                        confirmButtonColor: '#3085d6',
                        // showCancelButton: true,
                        confirmButtonText: 'ยืนยัน',
                        // cancelButtonText: 'ยกเลิก'
                    })
                    window.location.reload();
                  
                }else{
                    await Swal.fire({
                        title: 'Message',
                        text: 'Update contact success',
                        icon: 'success',
                        confirmButtonColor: '#3085d6',
                        // showCancelButton: true,
                        confirmButtonText: 'ยืนยัน',
                        // cancelButtonText: 'ยกเลิก'
                    })
                    window.location.reload();
                    navigate(-1)
                }
                
                
            }).catch(async (err:any)=>{
                const res = err.response.data
                await Swal.fire({
                    title: 'Update profile fail',
                    text: res.message,
                    icon: 'warning',
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'ok'
                })
            })
        // } catch (error) {
        //     console.log(error);
        // }

        // if (profile.id ){
        //     data = {
        //         "records": [
        //             {
        //                 "id":profile.id,
        //                 "fields": dataUser
        //             }
        //         ]
        //     }
        //     try {
        //         await axios.patch(`https://api.airtable.com/v0/appUuOWAHjGFOgM4M/form`,data,{
        //         headers: {
        //             'Content-Type': 'application/json;charset=UTF-8',
        //             'accept': 'application/json',
        //             'Authorization': `Bearer keyXS39pJOlAd3YW3`
    
        //         }}).then(async (res)=>{
        //             await Swal.fire({
        //                 title: 'Message',
        //                 text: 'Update contact success',
        //                 icon: 'success',
        //                 confirmButtonColor: '#3085d6',
        //                 // showCancelButton: true,
        //                 confirmButtonText: 'ยืนยัน',
        //                 // cancelButtonText: 'ยกเลิก'
        //             })
        //             // window.location.reload();
        //             navigate(-1)
        //         })
        //     } catch (error) {
        //         console.log(error);
        //     }
        // }else{
        //     data ={
        //         "action": 'update',
        //         "payload": {
        //             "walletAdress": dataUser.WalletAddress,
        //             "firstName": dataUser.FirstName,
        //             "lastName": dataUser.LastName,
        //             "tel": dataUser.Tel,
        //             "email": dataUser.Email,
        //             "shippingAddress": dataUser.Address,
        //             "contractName": dataUser.ContactName,
        //             "zipCode": dataUser.Zipcode,
        //             "contactTel": dataUser.ContactTel,
        //             "props": {}
        //         }
        //     }
        //     try {
        //         await axios.post(`https://api.airtable.com/v0/appUuOWAHjGFOgM4M/form`,data,{
        //         headers: {
        //             'Content-Type': 'application/json;charset=UTF-8',
        //             // 'accept': 'application/json',
        //             'Authorization': `Bearer keyXS39pJOlAd3YW3`
    
        //         }}).then(async (res)=>{
        //             // await Swal.fire({
        //             //     title: 'Message',
        //             //     text: 'Update contact success',
        //             //     icon: 'success',
        //             //     confirmButtonColor: '#3085d6',
        //             //     // showCancelButton: true,
        //             //     confirmButtonText: 'ยืนยัน',
        //             //     // cancelButtonText: 'ยกเลิก'
        //             // })
        //             // window.location.reload();
        //             // navigate(-1)
        //         })
        //     } catch (error) {
        //         console.log(error);
        //     }
        // }
  
       
        
    }

    const validate = ()=>{
        let Address= "";
        let ContactName="";
        let Zipcode="";
        let ContactTel="";  
      
        
        if (!dataUser.Address) {
            Address = "Address field is required";
        }

        if (!dataUser.ContactName) {
            ContactName = "ContactName field is required";
        }
        if (!dataUser.ContactTel) {
            ContactTel = "ContactTel field is required";
        }
        if (!dataUser.Zipcode) {
            Zipcode = "Zipcode field is required";
        }
      
        if (Address || ContactName || Zipcode || ContactTel) {
            
            setDataError((prevState: any) => ({
                ...prevState,
                'Address': Address,
                'ContactName': ContactName,
                'Zipcode': Zipcode,
                'ContactTel': ContactTel,

            }));
            return false;
          }
          return true;
          
    }
    let StatusText = ''
    switch (vertified) {
        case 'NONE':
            StatusText = 'Verify'
            break;
        case 'PENDING':
            StatusText = 'Pending'
            break;
        case 'APPROVED':
            StatusText = 'Verified'
            break;
    }

    if (!Account){
        return <Loader/>
    }

    return (
        <main>
            <div className="max-w-7xl mx-auto py-6 lg:px-8 ">
                {/* header */}
                <Title accout={Account} title={'Profile'}/>
                {/* card */}

                <div className="mt-10 sm:mt-0">
                    <div className="md:grid md:grid-cols-3 md:gap-6">
                    <div className="md:col-span-1">
                        <div className="px-4 sm:px-0">
                            <h3 className="text-lg font-medium leading-6 text-gray-900">Personal Information</h3>
                            {/* <p className="mt-1 text-sm text-gray-600">Use a permanent address where you can receive mail.</p> */}
                        </div>
                    </div>
                    <div className="mt-5 md:mt-0 md:col-span-2">
                        <form action="#" method="POST">
                        <div className="shadow overflow-hidden sm:rounded-md">
                            <div className="px-4 py-5 bg-white sm:p-6">
                            <div className="grid grid-cols-6 gap-6">
                                <div className="col-span-6 sm:col-span-3">
                                <label htmlFor="FirstName" className="block text-sm font-medium text-gray-700">
                                    First name
                                </label>
                                <input
                                 
                                    type="text"
                                    name="FirstName"
                                    id="FirstName"
                                    value={dataUser.FirstName}
                                    onChange={handleChange}
                                    className="px-2 mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md h-10 border"
                                />
                                </div>

                                <div className="col-span-6 sm:col-span-3">
                                <label htmlFor="LastName" className="block text-sm font-medium text-gray-700">
                                    Last name
                                </label>
                                <input
                                    
                                    type="text"
                                    name="LastName"
                                    id="LastName"
                                    value={dataUser.LastName}
                                    onChange={handleChange}
                                    className="px-2  mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md h-10 border"
                                />
                                </div>

                                <div className="col-span-7 sm:col-span-5">
                                    <label htmlFor="Email" className="block text-sm font-medium text-gray-700">
                                        Email
                                    </label>
                                    <div className='flex flex-row'>
                                        <input
                                            type="text"
                                            name="Email"
                                            id="Email"
                                            autoComplete="email"
                                            value={dataUser.Email}
                                            onChange={handleChange}
                                            className="px-2 mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md h-10 border"
                                        />
                                        <p

                                            className="w-40 ml-5 bg-white px-2 py-3  h-10 mt-1
                                            border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 
                                            bg-gray-100 focus:outline-none text-center"
                                        >
                                           {/* {vertified ? 'verified email':'not verified email'}  */}
                                           {StatusText}
                                        </p>
                                    </div>
                                </div>
                                <div className="col-span-6 sm:col-span-4">
                                    <label htmlFor="Tel" className="block text-sm font-medium text-gray-700">
                                        Tel
                                    </label>
                                    <input
                                        type="text"
                                        name="Tel"
                                        id="Tel"
                                        value={dataUser.Tel}
                                        onChange={handleChange}
                                        onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }}
                                        maxLength={10}
                                        className="px-2 mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md h-10 border"
                                    />
                                </div>
                                
                                <div className="col-span-6 sm:col-span-4">
                                    <h1>Delivery Address</h1>
                                    <br />
                                    <label htmlFor="ContactName" className="block text-sm font-medium text-gray-700">
                                        Contact Name
                                    </label>
                                    <input
                                        type="text"
                                        name="ContactName"
                                        id="ContactName"
                                        value={dataUser.ContactName}
                                        onChange={handleChange}
                                        className="px-2 mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md h-10 border"
                                    />
                                    {
                                        dataError.ContactName ?  
                                        <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                                        {dataError.ContactName }
                                        </span>: ""
                                    }
                                </div>
                              
                                <div className="col-span-6">
                                <label htmlFor="Address" className="block text-sm font-medium text-gray-700">
                                    Contact Address
                                </label>
                                <input
                                    type="text"
                                    name="Address"
                                    id="Address"
                                    value={dataUser.Address}
                                    onChange={handleChange}
                                    className="px-2 mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md h-10 border"
                                />
                                 {
                                        dataError.Address ?  
                                        <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                                        {dataError.Address }
                                        </span>: ""
                                    }
                                </div>

                                <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                <label htmlFor="Zipcode" className="block text-sm font-medium text-gray-700">
                                    ZIP code
                                </label>
                                <input
                                    type="text"
                                    name="Zipcode"
                                    id="Zipcode"
                                    value={dataUser.Zipcode}
                                    onChange={handleChange}
                                    onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}
                                    maxLength={5}
                                    className="px-2 mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md h-10 border"
                                />
                                   {
                                        dataError.Zipcode ?  
                                        <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                                        {dataError.Zipcode }
                                        </span>: ""
                                    }
                                </div>
                                <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                <label htmlFor="ContactTel" className="block text-sm font-medium text-gray-700">
                                    Contact Tel
                                </label>
                                <input
                                    type="text"
                                    name="ContactTel"
                                    id="ContactTel"
                                    value={dataUser.ContactTel}
                                    onChange={handleChange}
                                    onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}
                                    maxLength={10}
                                    className="px-2 mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md h-10 border"
                                />
                                   {
                                        dataError.ContactTel ?  
                                        <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                                        {dataError.ContactTel }
                                        </span>: ""
                                    }
                                </div>

                               
                            </div>
                            </div>
                            <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                            

                            <button
                                onClick={submit}
                                // disabled={vertified === 'PENDING'?true:false}
                                type="button"
                                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                                Save
                            </button>
                            <button
                                onClick={()=> navigate(-1)}
                                type="button"
                                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 ml-2"
                            >
                                back
                            </button>
                            
                            </div>
                        </div>
                        </form>
                    </div>
                    </div>
                </div>


      
            
            </div>
        </main>
    )
}

export default index