/* eslint-disable react-hooks/rules-of-hooks */
import React,{useEffect,useState}  from 'react';
import { useNavigate } from "react-router-dom"; 

/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
*/
const callouts = [
    {
      name: 'รหัส 1704086000001',
      description: 'กล้อง GoPro HERO10 Black',
      imageSrc: 'https://mercular.s3.ap-southeast-1.amazonaws.com/images/products/2021/11/Product/gopro-hero-10-black.jpg',
      imageAlt: 'Desk with leather desk pad, walnut desk organizer, wireless keyboard and mouse, and porcelain mug.',
      href: '#',
    },
  ]


  
  export default function Example() {


    const navigate = useNavigate();

    const goto = ()=>{
        navigate('/detail_manage');
    }

    return (
      <div className="bg-gray-100">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="max-w-2xl mx-auto py-16 sm:py-24 lg:py-32 lg:max-w-none">
            <h2 className="text-2xl font-extrabold text-gray-900">Products</h2>
  
            <div className="mt-6 space-y-12 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-x-6">
              {callouts.map((callout) => (
                <div key={callout.name} className="group relative" onClick={()=> goto()}>
                  <div className="relative w-full h-80 bg-white rounded-lg overflow-hidden group-hover:opacity-75 sm:aspect-w-2 sm:aspect-h-1 sm:h-64 lg:aspect-w-1 lg:aspect-h-1">
                    <img
                      src={callout.imageSrc}
                      alt={callout.imageAlt}
                      className="w-full h-full object-center object-cover"
                    />
                  </div>
                  <h3 className="mt-6 text-sm text-gray-500">
                    <a href={callout.href}>
                      <span className="absolute inset-0" />
                      {callout.name}
                    </a>
                  </h3>
                  <p className="text-base font-semibold text-gray-900">{callout.description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    )
  }

  
// import { Navigate } from "react-router-dom"; 

  
// import groupBy from "../contracts/groupBy";
// import token from "../contracts/token";

// const ADDRESS  = '0x03c1C85bCDb0Fb14afb45f48abF3Cc0f8220808D';
// function manage() {
//     const [redirctTo, setRedirctTo] = useState(false); 
//     const [account,SetAccount] = useState('');
//     const [address,SetAddress] = useState([]);

//   // eslint-disable-next-line react-hooks/rules-of-hooks
//     useEffect(() => {
//         const getContract = async () => {
//         const accounts = localStorage?.getItem('WalletAddress')
//         SetAccount(accounts)

        
//         if(accounts){
//             const products = {
//                 qty_current : await groupBy.methods.get_current_qty().call(),
//                 max : await groupBy.methods.get_maximum_target_quantity().call(),
//                 min : await groupBy.methods.get_minimum_garget_quantity().call(),
//                 id : await groupBy.methods.get_entity_id().call(),
//                 name : await groupBy.methods.get_entity_name().call(),
//                 price : await groupBy.methods.get_entity_price().call(),
//                 user_order : await groupBy.methods.get_quantityOf(accounts).call(),
//                 allow : await token.methods.allowance(accounts,ADDRESS).call(),
//                 expired : await groupBy.methods.get_release_time().call(),
//             }

//         }   
        
//         const manager = await groupBy.methods.owner().call();

//         if (manager !== accounts){
//             setRedirctTo(true)
//         }
            
    
//         }

//     getContract()
//   }, [])


//   const refund = async () =>{
//     await groupBy.methods.refund().send({
//       from: account
//     });
//     window.location.reload();
//   }

//   const refund_seller = async () =>{
//     await groupBy.methods.refund_seller().send({
//       from: account
//     });
//     window.location.reload();
//   }

//   const getAddress = async () =>{
//     const data = await groupBy.methods.get_approved().call({
//         from: account
//       })
//       SetAddress(data)
//     }


//   if(redirctTo){
//     return <Navigate to="/" />
//   }
  

//   return <div>
//             <section className="bg-white py-8">
//             <div className="container mx-auto flex items-center flex-wrap pt-4 pb-12">
//             <nav id="store" className="w-full z-30 top-0 px-6 py-1">
       
//                 <div className="w-full container mx-auto flex flex-wrap items-center justify-between mt-0 px-2 py-3">
//                         <div className="uppercase tracking-wide no-underline hover:no-underline font-bold text-gray-800 text-xl ">
//                             Manage Product
//                         </div>
//                 </div>
                
//             </nav>
//                 <div className='flex'>
//                         <button onClick={()=>refund()} className="py-2  text-md font-bold text-white rounded-md w-56 bg-blue-600 hover:bg-blue-800">Refund </button>
//                         <button onClick={()=>refund_seller()} className="py-2 ml-2 text-md font-bold text-white rounded-md w-56 bg-blue-600 hover:bg-blue-800">Claim</button>
//                         <button onClick={()=>getAddress()} className="py-2 ml-2 text-md font-bold text-white rounded-md w-56 bg-blue-600 hover:bg-blue-800">get Address</button>

//                     </div>
//                 </div>
//                 <div className='flex w-full container mx-auto flex flex-wrap items-center  mt-0 px-2 py-3'>
//                     <h4>Address</h4>
//                     <span>{address ? JSON.stringify(address) :''}</span>                    
//                 </div>
//             </section>

//   </div>;
// }

// export default manage;
