/*eslint-disable */

import "./App.css";
import { useState } from "react";
import { WEB3AUTH_NETWORK_TYPE } from "@config/web3AuthNetwork";
import { CHAIN_CONFIG_TYPE } from "@config/chainConfig";
// import styles from "./styles/Home.module.css";
import { Web3AuthProvider } from "@services/web3auth";
import { Web3AuthProviderWallet } from "@services/web3authWallet";
import { EthSWRConfig } from "ether-swr";
import Login from "@components/Login/Index";
import { ethers } from "ethers";
import ERC20ABI from './ERC20.abi.json'
import CacheBuster from 'react-cache-buster';
import packageJson from '../package.json';

import Loader from "@components/Loader";
import OneSignal from 'react-onesignal';


const CHAIN = (process.env.NODE_ENV === "production") ? 'mainnet':'testnet'

if (process.env.NODE_ENV === "production")
  console.log = () => {};
  

function App() {
  const [web3AuthNetwork, setWeb3AuthNetwork] = useState<WEB3AUTH_NETWORK_TYPE>(CHAIN);
  const [chain, setChain] = useState<CHAIN_CONFIG_TYPE>("bsc");
  const customHttpProvider = new ethers.providers.JsonRpcProvider('https://data-seed-prebsc-1-s2.binance.org:8545/');
  const ABIs:any = [
    ['0x66E70704c67f3e7242387083B0C3BC3a5E1879F6', ERC20ABI],
    // ['0xf1ea1E72616e285C4d8c229E0f95aEfFEf35F641', ERC20ABI],
    ['0x337610d27c682E347C9cD60BD4b3b107C9d34dDd', ERC20ABI]
  ]

  const isProduction = process.env.NODE_ENV === 'production';

  const [initialized, setInitialized] = useState(false);

  // OneSignal.init({ 
  //   appId: '3c10b782-b6c1-4e60-9fbf-beae1df00ae8' ,
  //   // serviceWorkerParam: { scope: "/js/push/onesignal/" },
  //   // serviceWorkerPath:'../OneSignalSDKWorker.js',
  // }).then(() => {
  //     setInitialized(true);
  //     OneSignal.showSlidedownPrompt().then(() => {
  //       // do other stuff
  //     });
  // })

  
  return (
    <CacheBuster
        currentVersion={packageJson.version}
        isEnabled={isProduction} //If false, the library is disabled.
        isVerboseMode={false} //If true, the library writes verbose logs to console.
        loadingComponent={<Loader />} //If not pass, nothing appears at the time of new version check.
      >
      <Web3AuthProvider chain={chain} web3AuthNetwork={web3AuthNetwork}>
         <Web3AuthProviderWallet chain={chain} web3AuthNetwork={web3AuthNetwork}>
          <EthSWRConfig
            value={{ web3Provider: customHttpProvider, ABIs: new Map(ABIs), refreshInterval: 15000 }}
          >
            {/* {user ? <Main/> :  <Login />} */}
              <Login/>
            </EthSWRConfig>
          </Web3AuthProviderWallet>
      </Web3AuthProvider>
    </CacheBuster>
  );
}

export default App;
