import React from 'react'
import moment from 'moment';

function List({account,item,index,groupBuy}:{account:any,item: any,index: any,groupBuy:any}) {

    let data_type = 'รายละเอียดการจอง';
    let amount = item.price
    
    let color = ''
    let StatusText = ''
    switch (item.status) {
        case 'REGISTER_SUCCESS':
            color = 'green'
            StatusText = 'สั่งจองสำเร็จ'
            break;
        case 'REGISTER_FAILED':
            color = 'red'
            StatusText = 'สั่งจองไม่สำเร็จ'
            break;
        case 'SHIPPING_PROCESSING':
            color = 'blue'
            StatusText = 'กำลังจัดส่ง'
            break;
        case 'SHIPPING_SUCCESS':
            color = 'green'
            StatusText = 'จัดส่งสำเร็จ'
            break;
        case 'SHIPPING_FAILED':
            color = 'red'
            StatusText = 'ขนส่งไม่สำเร็จ'
            break;
        case 'ORDER_CANCELED':
            color = 'red'
            StatusText = 'ยกเลิกการสั่งจอง'
            break;
        default:
            color = 'green'
            StatusText = 'ดำเนินการเรียบร้อย'
            break;
    }


    const cancelOrder  =(contractAddress: any)=>{
        return groupBuy(contractAddress)
    }
   
    return (
        <div key={index} className='flex flex-row border py-4 my-2 px-4 rounded shadow-md text-gray-500 hover:bg-white/[0.12] hover:text-gray-900 hover:bg-gray-50 cursor-pointer'>
            <div className='w-1/4 flex space-x-2 justify-start flex-col  items-start border-r-2 border-gray-400 mx-4'>
                <div className='text-xs'>{ moment(item.requestDate).format("D MMM YYYY")}</div>
                <div className='text-xs'>{ moment(item.requestDate).format("H:mm:ss")}</div>
            </div>
            <div className='w-2/4  flex justify-start flex-col '>
                <div className='text-sm'>เลขที่สั่งจอง : {item.runno}</div>
                <div className='text-sm'>{data_type}</div>
                <div className='text-sm'>ชื่อสินค้า : {item.productName}</div>
                <div className='text-sm'>จำนวนที่สั่ง : {item.amount} ชื้น</div>
                <div className='text-sm'>ราคา 5000 บาท ({amount} USDT)</div>
                {item.status !== 'ORDER_CANCELED' &&  <div>
                    <button className={`bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-4 rounded`} onClick={()=>cancelOrder(item.contractAddress)} >ยกเลิก</button>
                </div>}
               
            </div>
            {/* <div className={`w-3/4  flex justify-center text-sm ${data_type === 'Sent'?'text-red-500':'text-green-500'}`}>{amount} WOM</div> */}
            <div className='w-3/4  flex justify-start flex-col items-end'>
                <div className='flex justify-center'>
                    {/* {item.isError === '0'?
                    <div className="p-2 text-xs  bg-green-200 text-green-800 rounded-full">Successful</div>
                    :<div className="p-2  text-xs  bg-red-200 text-red-800 rounded-full">Fail</div>
                    } */}
                    <div className={`p-2 text-xs  bg-${color}-200 text--${color}-800 rounded`}>{StatusText}</div>
                </div>
                <div className='flex justify-center p-2'>
                <a href={`https://testnet.bscscan.com/tx/${item.transactionHash}`} className='flex text-xs hover:text-blue-600' target="_blank" rel="noreferrer" >View Explorer</a>
                </div>
                
            </div>
        </div>
    )
}

export default List