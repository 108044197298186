import {ethers} from "ethers";
import { formatEther, formatUnits } from '@ethersproject/units'
import useEtherSWR, { EthSWRConfig } from 'ether-swr'

export const TokenBalance = ({address, decimals,account,className}: {
    address: string
    decimals: number
    account: string
    className?: string
  }) => {
 
    const { data: balance } = useEtherSWR([address, 'balanceOf', account])
  
    if (!balance) {
      return <div className={`${className}`}>0</div>
    }
    return (
         <div className={`${className}`}> {parseFloat(formatUnits(balance, decimals))}</div>

    )
  }