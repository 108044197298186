/* eslint-disable react-hooks/rules-of-hooks */
import React,{ useState } from 'react'
import { QrReader } from 'react-qr-reader';

function index() {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [data, setData] = useState('No result');
    const width:any =  {
      width:'30'
    }
    return (
      <div className=" mx-auto py-2">
      <QrReader
          onResult={(result: any, error) => {
            if (!!result) {
              setData(result?.text);
            }

            if (!!error) {
              console.info(error);
            }
          } }

          containerStyle={{ width }} constraints={{}}      />
      <p>{data}</p>
    </div>
    )
}

export default index