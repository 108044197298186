/* eslint-disable max-len */
import { ADAPTER_EVENTS, CHAIN_NAMESPACES, SafeEventEmitterProvider, WALLET_ADAPTER_TYPE } from "@web3auth/base";
import type { LOGIN_PROVIDER_TYPE } from "@toruslabs/openlogin";

import { Web3AuthCore } from "@web3auth/core";
import { Web3Auth } from "@web3auth/web3auth";
import { OpenloginAdapter } from "@web3auth/openlogin-adapter";
import { createContext, FunctionComponent, ReactNode, useCallback, useContext, useEffect, useState } from "react";
import { CHAIN_CONFIG, CHAIN_CONFIG_TYPE } from "@config/chainConfig";
import { WEB3AUTH_NETWORK_TYPE } from "@config/web3AuthNetwork";
import { getWalletProvider, IWalletProvider } from "./walletProvider";
import { MetamaskAdapter } from "@web3auth/metamask-adapter";
import { EthereumPrivateKeyProvider } from "@web3auth/ethereum-provider";

export interface IWeb3AuthContext {
  web3Auth: Web3AuthCore | null | any;
  provider: any;
  privateKey: any;
  isLoading: boolean;
  user: unknown;
  login: (adapter: WALLET_ADAPTER_TYPE,provider: LOGIN_PROVIDER_TYPE, login_hint?: string) => Promise<void>;
  logout: () => Promise<void>;
  getUserInfo: () => Promise<any>;
  signMessage: () => Promise<any>;
  getAccounts: () => Promise<any>;
  getBalance: () => Promise<any>;
}

export const Web3AuthContext = createContext<IWeb3AuthContext>({
  web3Auth: null,
  provider: null,
  privateKey: null,
  isLoading: false,
  user: null,
  login: async (adapter: WALLET_ADAPTER_TYPE, provider?: LOGIN_PROVIDER_TYPE, login_hint?: string) => {},
  logout: async () => {},
  getUserInfo: async () => {},
  signMessage: async () => {},
  getAccounts: async () => {},
  getBalance: async () => {},
});

export function useWeb3Auth() {
  return useContext(Web3AuthContext);
}

interface IWeb3AuthState {
  web3AuthNetwork: WEB3AUTH_NETWORK_TYPE;
  chain: CHAIN_CONFIG_TYPE;
}
interface IWeb3AuthProps {
  children?: ReactNode;
  web3AuthNetwork: WEB3AUTH_NETWORK_TYPE;
  chain: CHAIN_CONFIG_TYPE;
}

export const Web3AuthProvider: FunctionComponent<IWeb3AuthState> = ({ children, web3AuthNetwork, chain }: IWeb3AuthProps) => {
  const [web3Auth, setWeb3Auth] = useState<Web3AuthCore | null>(null);
  const [provider, setProvider] = useState<any>(null);
  const [privateKey, setPrivateKey] = useState<any>(null);
  const [user, setUser] = useState<unknown | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const setWalletProvider = useCallback(
    (web3authProvider: SafeEventEmitterProvider) => {
      const walletProvider = getWalletProvider(chain, web3authProvider, uiConsole);
      setProvider(walletProvider);
    },
    [chain]
  );

  useEffect(() => {
    const subscribeAuthEvents = (web3auth: Web3AuthCore) => {
      // Can subscribe to all ADAPTER_EVENTS and LOGIN_MODAL_EVENTS
      web3auth.on(ADAPTER_EVENTS.CONNECTED, async (data: unknown) => {
        console.log("Yeah!, you are successfully logged in", data);
        setUser(data);
        // setWalletProvider(web3auth.provider!);
 
        setProvider('https://rpc.omplatform.com/');
        //get private key
        const privateKey:any = await web3auth.provider!.request({
            method: "private_key"
        });
        setPrivateKey(privateKey)
        localStorage.setItem('isWalletConnected', 'true')
      });

      web3auth.on(ADAPTER_EVENTS.CONNECTING, () => {
        console.log("connecting");
        setIsLoading(true);
        localStorage.setItem('isWalletConnected', 'false')
      });

      web3auth.on(ADAPTER_EVENTS.DISCONNECTED, () => {
        console.log("disconnected");
        setUser(null);
        setIsLoading(false);
        localStorage.setItem('isWalletConnected', 'false')
      });

      web3auth.on(ADAPTER_EVENTS.ERRORED, (error: unknown) => {
        console.error("some error or user has cancelled login request", error);
        localStorage.setItem('isWalletConnected', 'false')
      });
    };

    const currentChainConfig = CHAIN_CONFIG[chain];
    
    async function init() {
      try {
        setIsLoading(true);
        const clientId = "BA4jrb8ITCdUl0pVT-FesR5DT1IZc_mH3oC-NSKF2w27o8tYv9bVfL2BYDMjsbjmDkWq53uWIVYZ-XfxKGkSVIc";
        const web3AuthInstance = new Web3AuthCore({
          chainConfig: currentChainConfig,
          // chainConfig: { chainNamespace: 'other' },
          // clientId: clientId
        });

        
        subscribeAuthEvents(web3AuthInstance);

        const adapter = new OpenloginAdapter({ adapterSettings: { network: web3AuthNetwork, clientId, uxMode: 'popup'} });
        web3AuthInstance.configureAdapter(adapter);
        // const config:any = {
        //   chainNamespace: CHAIN_NAMESPACES.EIP155,
        //   rpcTarget: "https://rpc.omplatform.com/",
        //   blockExplorer: "https://evm-explorer.omplatform.com/",
        //   chainId: "0x4DE",
        //   displayName: "Central Chain Mainnet",
        //   ticker: "ccoin",
        //   tickerName: "CCOIN",
        // }
        // const metamaskAdapter = new MetamaskAdapter(config);
        // web3AuthInstance.configureAdapter(metamaskAdapter);

  
        await web3AuthInstance.init();
        setWeb3Auth(web3AuthInstance);
      
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    }

    init();

  }, [chain, web3AuthNetwork, setWalletProvider]);


  const login = async (adapter: WALLET_ADAPTER_TYPE, loginProvider: LOGIN_PROVIDER_TYPE|null, login_hint?: string) => {
    try {
      setIsLoading(true);
      if (!web3Auth) {
        console.log("web3auth not initialized yet");
        uiConsole("web3auth not initialized yet");
        return;
      }
      const localProvider = await web3Auth.connectTo(adapter, { loginProvider, login_hint });
      // setWalletProvider(localProvider!);
      setProvider('https://rpc.omplatform.com/');

      // localStorage.setItem('isWalletConnected', 'true')
     
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsLoading(false)
    }
  };


  const logout = async () => {
    if (!web3Auth) {
      console.log("web3auth not initialized yet");
      uiConsole("web3auth not initialized yet");
      return;
    }
    await web3Auth.logout();
    setProvider(null);
    setPrivateKey(null)
    localStorage.removeItem("isWalletConnected");
    // window.location.reload();
    window.location.href = "/";
  };

  const getUserInfo = async () => {

    if (!web3Auth) {
      console.log("web3auth not initialized yet");
      // uiConsole("web3auth not initialized yet");
      return;
    }
    const user = await web3Auth.getUserInfo();
    return user;
  };

  const getAccounts = async () => {

    
    if (!provider) {
      console.log("provider not initialized yet");
      uiConsole("provider not initialized yet");

      return;
    }
    provider.getAccounts()
  };

  const getBalance = async () => {
    if (!provider) {
      console.log("provider not initialized yet");
      uiConsole("provider not initialized yet");
      return;
    }
    provider.getBalance();
  };

  const signMessage = async () => {
    if (!provider) {
      console.log("provider not initialized yet");
      uiConsole("provider not initialized yet");
      return;
    }
    provider.signMessage();
  };

  const uiConsole = (...args: unknown[]): void => {
    // const el = document.querySelector("#console>p");
    // if (el) {
    //   el.innerHTML = JSON.stringify(args || {}, null, 2);
    // }
  };

  const contextProvider = {
    web3Auth,
    provider,
    privateKey,
    user,
    isLoading,
    login,
    logout,
    getUserInfo,
    getAccounts,
    getBalance,
    signMessage,
  };
  return <Web3AuthContext.Provider value={contextProvider}>{children}</Web3AuthContext.Provider>;
};
// function getPrivateKey() {
//   throw new Error("Function not implemented.");
// }

