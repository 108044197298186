/* eslint-disable react-hooks/rules-of-hooks */
import React,{useEffect,useState,Fragment}  from 'react';
import moment from "moment";
import {
  BriefcaseIcon,
  CalendarIcon,
  CheckIcon,
  ChevronDownIcon,
  CurrencyDollarIcon,
  LinkIcon,
  LocationMarkerIcon,
  PencilIcon,
} from '@heroicons/react/solid'
import { Menu, Transition } from '@headlessui/react'

import { PaperClipIcon } from '@heroicons/react/solid'
import { useWeb3Auth } from "@services/web3auth";
import { useWeb3AuthWallet } from "@services/web3authWallet";
import Web3 from "web3";
import {useNavigate} from 'react-router-dom';


import { Navigate } from "react-router-dom"; 

  
import {ABI, ADDRESS} from '@contracts/groupBy'
import {ABI as ABITOKEN } from '@contracts/token'



const people = [
    {
      name: 'Jane Cooper',
      title: 'Regional Paradigm Technician',
      department: 'Optimization',
      role: 'Admin',
      email: 'jane.cooper@example.com',
      image:
        'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60',
    },
    // More people...
  ]

  
function manage() {
    const [redirctTo, setRedirctTo] = useState(false); 
    const [account,SetAccount] = useState('');
    const [address,SetAddress] = useState([]);
    const { provider, privateKey } = useWeb3Auth();
    const {web3Auth,WalletConnectProvider,connected} = useWeb3AuthWallet();
  
    const [products,setProducts] = useState<any>({});
    const [disabled,setDisabled] = useState(false);
    const [Account,setAccount] = useState('');
  
    const [groupBuy,setGroupBuy] = useState<any>(undefined);
    // const [contractToken,setContractToken] = useState<any>(undefined);
  
    const navigate = useNavigate();
  
    const abi:any = ABI
    const token_abi:any = ABITOKEN
    
    useEffect(() => {
      const getAccout = async ()=>{
        try {
  
          const web3 = new Web3(provider);
          const accounts = web3.eth.accounts.privateKeyToAccount(privateKey);
          const groupbuy = new web3.eth.Contract(abi, ADDRESS);
          // const token = new web3.eth.Contract(token_abi, token_address);
          setAccount(accounts.address)
          setGroupBuy(groupbuy)
          // setContractToken(token)
        } catch (error) {
          console.log(error);
          
        }
     
      }
      if (privateKey)
        getAccout();
      return () => {}
    }, [privateKey]);
  
    useEffect(() => {
      const getAccout = async ()=>{
        try {
          const providers = WalletConnectProvider ?? web3Auth.provider 
          const web3 = new Web3(providers);
          const accounts = await web3.eth.getAccounts();
          const groupbuy = new web3.eth.Contract(abi, ADDRESS);
          // const token = new web3.eth.Contract(token_abi, token_address);
  
          setGroupBuy(groupbuy)
          // setContractToken(token)
          setAccount(accounts[0])
        } catch (error) {
          console.log(error);
          
        }
     
      }
      if (connected)
        getAccout();
      return () => {}
    }, [connected]);
  
    useEffect(() => {
  
      const initContract = async ()=>{
          try {
            const item = {
              qty_current : await groupBuy.methods.get_current_qty().call(),
              max : await groupBuy.methods.get_maximum_target_quantity().call(),
              min : await groupBuy.methods.get_minimum_garget_quantity().call(),
              id : await groupBuy.methods.get_entity_id().call(),
              name : await groupBuy.methods.get_entity_name().call(),
              price : await groupBuy.methods.get_entity_price().call(),
              user_order :  Account ? await groupBuy.methods.get_quantityOf(Account).call():0,
              expired : await groupBuy.methods.get_release_time().call(),
            }
            setProducts(item);
          }catch(error){
            console.log(error);
          }
      }
  
      if (groupBuy) initContract();
      return () => {
        
      }
    }, [groupBuy]);

  function classNames(...classes: any[]) {
    return classes.filter(Boolean).join(' ')
  }

  const refund = async () =>{
    await groupBuy.methods.refund().send({
      from: account
    });
    window.location.reload();
  }

  const refund_seller = async () =>{
    await groupBuy.methods.refund_seller().send({
      from: account
    });
    window.location.reload();
  }

  const getAddress = async () =>{
    const data = await groupBuy.methods.get_approved().call({
        from: account
      })
      SetAddress(data)
    }


  if(redirctTo){
    return <Navigate to="/" />
  }

  if(!products.id){
    return (
        // <div className='container mx-auto flex items-center flex-wrap pt-4 pb-12'>
            
        // </div>
  
  
        <div className="flex h-screen">
          <div className="m-auto">
              <div className="flex justify-center items-center flex-wrap pt-4 pb-12">
                Processing...
              </div>
          </div>
        </div>
  
     )
  }

  return <div>
            <section className="bg-white py-8">
                
            <div className="container mx-auto flex items-center flex-wrap pt-2 pb-1">
                <div className="lg:flex lg:items-center lg:justify-between w-full">
                <div className="flex-1 min-w-0">
                    <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">{products.name}</h2>
                    <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6">
                    {/* <div className="mt-2 flex items-center text-sm text-gray-500">
                        <BriefcaseIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                        Full-time
                    </div>
                    <div className="mt-2 flex items-center text-sm text-gray-500">
                        <LocationMarkerIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                        Remote
                    </div> */}
                    <div className="mt-2 flex items-center text-sm text-gray-500">
                        <CurrencyDollarIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                    
                        {products.price} USDT
                    </div>
                    <div className="mt-2 flex items-center text-sm text-gray-500">
                        <CalendarIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                        สิ้นสุดแคมเปญ {moment(parseInt(products.expired+'000')).format("DD/MM/YYYY")}
                    </div>
                    </div>
                </div>
                <div className="mt-5 flex lg:mt-0 lg:ml-4">
                    <span className="hidden sm:block">
                    <button
                        onClick={()=>refund()}
                        type="button"
                        className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                        <PencilIcon className="-ml-1 mr-2 h-5 w-5 text-gray-500" aria-hidden="true" />
                        Refund
                    </button>
                    </span>

                    <span className="hidden sm:block ml-3">
                    <button
                        onClick={()=>getAddress()}
                        type="button"
                        className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                        <LinkIcon className="-ml-1 mr-2 h-5 w-5 text-gray-500" aria-hidden="true" />
                        get Address
                    </button>
                    </span>

                    <span className="sm:ml-3">
                    <button
                        onClick={()=>refund_seller()}
                        type="button"
                        className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                        <CheckIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                        Claim
                    </button>
                    </span>
                </div>
                </div>
                {/* <div className='flex'>
                        <button onClick={()=>refund()} className="py-2  text-md font-bold text-white rounded-md w-56 bg-blue-600 hover:bg-blue-800">Refund </button>
                        <button onClick={()=>refund_seller()} className="py-2 ml-2 text-md font-bold text-white rounded-md w-56 bg-blue-600 hover:bg-blue-800">Claim</button>
                        <button onClick={()=>getAddress()} className="py-2 ml-2 text-md font-bold text-white rounded-md w-56 bg-blue-600 hover:bg-blue-800">get Address</button>

                    </div> */}
                </div>
                <div className='flex w-full container mx-auto flex flex-wrap items-center  mt-0 px-2 py-3'>
                                       {/* <span>{address ? JSON.stringify(address) :''}</span> */}
                    <div className="flex flex-wrap mb-2 w-full">
                        <div className="w-full md:w-1/2 xl:w-1/3 pt-3 px-3 md:pr-3">
                            <div className="bg-green-600 border rounded shadow p-5">
                                <div className="flex flex-row items-center">
                                    <div className="flex-shrink pl-1 pr-4"><i className="fa fa-wallet fa-2x fa-fw fa-inverse"></i></div>
                                    <div className="flex-1 text-right">
                                        <h5 className="text-white">เงินที่ได้ USDT</h5>
                                        <h3 className="text-white text-5xl">{products.price * products.qty_current}<span className="text-green-400"><i className="fas fa-caret-down"></i></span></h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-full md:w-1/2 xl:w-1/3 pt-3 px-3 md:pl-2">
                            <div className="bg-red-600 border rounded shadow p-5">
                                <div className="flex flex-row items-center">
                                    <div className="flex-shrink pl-1 pr-4"><i className="fas fa-users fa-2x fa-fw fa-inverse"></i></div>
                                    <div className="flex-1 text-right">
                                        <h5 className="text-white">จำนวนจอง (ชิ้น)</h5>
                                        <h3 className="text-white text-5xl">{products.qty_current} <span className="text-red-400"><i className="fas fa-caret-up"></i></span></h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-full md:w-1/2 xl:w-1/3 pt-3 px-3 md:pl-2">
                            <div className="bg-blue-600 border rounded shadow p-5">
                                <div className="flex flex-row items-center">
                                    <div className="flex-shrink pl-1 pr-4"><i className="fas fa-users fa-2x fa-fw fa-inverse"></i></div>
                                    <div className="flex-1 text-right">
                                        <h5 className="text-white">จำนวนสินค้าที่มี (ชิ้น)</h5>
                                        <h3 className="text-white text-5xl">{products.max} <span className="text-blue-400"><i className="fas fa-caret-up"></i></span></h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>  

                    <div className=''>
                        <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                            <div className="px-4 py-5 sm:px-6">
                                <h3 className="text-lg leading-6 font-medium text-gray-900">รายละเอียดแคมเปญ</h3>
                            </div>
                            <div className="border-t border-gray-200">
                                <dl>
                                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">รหัสแคมเปญ</dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">​{products.id}</dd>
                                </div>
                                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">ชื่อแคมเปญ</dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{products.name}</dd>
                                </div>
                                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">จำนวนขั้นต่ำ</dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{products.min}</dd>
                                </div>
                                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">จำนวนสินค้าที่มี</dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{products.max}</dd>
                                </div>
                                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">วันที่เคลมแคมเปญ</dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    {products.finished}

                                    </dd>
                                </div>
                                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">สถานะแคมเปญ</dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                            Active
                                        </span>
                                    </dd>
                                </div>
                        
                                </dl>
                            </div>
                        </div>

                        {/* <div className="bg-white shadow overflow-hidden sm:rounded-lg mt-5">
                            <div className="px-4 py-5 sm:px-6">
                                <h3 className="text-lg leading-6 font-medium text-gray-900">ข้อมูลผู้สั่งจอง</h3>
                            </div>
                            <div className="border-t border-gray-200">

                                <div className="flex w-full container mx-auto flex flex-wrap items-center  mt-0 px-2 py-3">
                                    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                            <table className="min-w-full divide-y divide-gray-200">
                                            <thead className="bg-gray-50">
                                                <tr>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >
                                                    Address Wallet
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >
                                                    ชื่อ
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >
                                                    ที่อยู่จัดส่ง
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >
                                                    Status
                                                </th>
                                            
                                                <th scope="col" className="relative px-6 py-3">
                                                    <span className="sr-only">Edit</span>
                                                </th>
                                                </tr>
                                            </thead>
                                            <tbody className="bg-white divide-y divide-gray-200">
                                                {people.map((person) => (
                                                <tr key={person.email}>
                                                    <td>
                                                        <div className="text-xs text-gray-900">0x8fa602a53104BA26420dDe50Fe52f0f36bA52C6A</div>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                    <div className="flex items-center">
                                                    
                                                        <div className="ml-4">
                                                            <div className="text-sm font-medium text-gray-900">{person.name}</div>
                                                            <div className="text-sm text-gray-500">{person.email}</div>
                                                        </div>
                                                    </div>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                    <div className="text-sm text-gray-900">{person.title}</div>
                                                    <div className="text-sm text-gray-500">{person.department}</div>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                                        Active
                                                    </span>
                                                    </td>
                                                
                                                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                                    <a href="#" className="text-indigo-600 hover:text-indigo-900">
                                                        confirm
                                                    </a>
                                                    </td>
                                                </tr>
                                                ))}
                                            </tbody>
                                            </table>
                                        </div>
                                        </div>
                                    </div>
                                </div>

                        
                            </div>
                        </div> */}
                    </div>

 
                        
                </div>
                
            </section>

  </div>;
}

export default manage;



