/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable max-len */
/* eslint-disable */

import React,{useEffect, useState} from 'react'
import {ABI, ADDRESS} from '@contracts/groupBy'
import {ABI as ABITOKEN } from '@contracts/token'
import {ABI as ABISIMPLE } from '@contracts/simple'
import { useWeb3Auth } from "@services/web3auth";
import { useWeb3AuthWallet } from "@services/web3authWallet";
import Web3 from "web3";
import {useNavigate} from 'react-router-dom';
import Title from '@components/Title'


import Countdown from 'react-countdown';
import moment from 'moment';
import Loader from '@components/Loader';

export interface InterfaceProduct {
  qty_current: number,
  max: number,
  min: number,
  id: any,
  name: any,
  price: number,
  expired: any,
}

const Dapp = () => {

  const { provider, privateKey } = useWeb3Auth();
  const {web3Auth,WalletConnectProvider,connected} = useWeb3AuthWallet();

  const [products,setProducts] = useState<any>({});
  const [disabled,setDisabled] = useState(false);
  const [Account,setAccount] = useState('');

  const [groupBuy,setGroupBuy] = useState<any>(undefined);
  // const [contractToken,setContractToken] = useState<any>(undefined);

  const navigate = useNavigate();

  const abi:any = ABI
  const token_abi:any = ABITOKEN
  
  useEffect(() => {
    const getAccout = async ()=>{
      try {

        const web3 = new Web3(provider);
        const accounts = web3.eth.accounts.privateKeyToAccount(privateKey);
        const groupbuy = new web3.eth.Contract(abi, ADDRESS);
        // const token = new web3.eth.Contract(token_abi, token_address);
        setAccount(accounts.address)
        setGroupBuy(groupbuy)
        // setContractToken(token)
      } catch (error) {
        console.log(error);
        
      }
   
    }
    if (privateKey)
      getAccout();
    return () => {}
  }, [privateKey]);

  useEffect(() => {
    const getAccout = async ()=>{
      try {
        const providers = WalletConnectProvider ?? web3Auth.provider 
        const web3 = new Web3(providers);
        const accounts = await web3.eth.getAccounts();
        const groupbuy = new web3.eth.Contract(abi, ADDRESS);
        // const token = new web3.eth.Contract(token_abi, token_address);

        setGroupBuy(groupbuy)
        // setContractToken(token)
        setAccount(accounts[0])
      } catch (error) {
        console.log(error);
        
      }
   
    }
    if (connected)
      getAccout();
    return () => {}
  }, [connected]);

  useEffect(() => {

    const initContract = async ()=>{
        try {
          const item = {
            qty_current : await groupBuy.methods.get_current_qty().call(),
            max : await groupBuy.methods.get_maximum_target_quantity().call(),
            min : await groupBuy.methods.get_minimum_garget_quantity().call(),
            id : await groupBuy.methods.get_entity_id().call(),
            name : await groupBuy.methods.get_entity_name().call(),
            price : await groupBuy.methods.get_entity_price().call(),
            user_order :  Account ? await groupBuy.methods.get_quantityOf(Account).call():0,
            expired : await groupBuy.methods.get_release_time().call(),
          }
          setProducts(item);
        }catch(error){
          console.log(error);
        }
    }

    if (groupBuy) initContract();
    return () => {
      
    }
  }, [groupBuy]);



  const renderer = ({ days,hours, minutes, seconds, completed }:{days:any,hours:any,minutes:any,seconds:any,completed:any,}):any => {
    
    if (completed) {
      // Render a completed state
      return setDisabled(true);
    } else {
      // Render a countdown
      return <span>{days} วัน {hours} ชั่วโมง {minutes} นาที</span>;
    }
  };


  const requestOrder = async (date_expire:any)=>{

    navigate('/dapp/products', { state: {products,date_expire}});
  }


  var expired:any = moment(new Date(parseInt(products.expired+'000')));
  var current:any = moment(new Date());
  let date_expire = false
  if (current > expired)  date_expire = true


  if (!products.id){
    return <Loader/>
  }
      
  var amount = products.max  - products.qty_current;
  var amount_per = (products.qty_current  * 100) / products.min;
  if (amount_per > 100){
    amount_per = 100
  }
  
  
  return (
    <>
      <main>
        <div className="max-w-7xl mx-auto py-2 sm:px-6 lg:px-8 sm:px-4">
          {/* header */}
          <Title accout={Account} title={'Markets'}/>
          {/* card */}
          <section className="bg-white">
            <div className="container mx-auto flex items-center flex-wrap pt-4 pb-12 justify-center">
          
                  <div className="grid grid-cols-1 gap-y-10 sm:grid-cols-2 gap-x-6 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8 px-4 ">
                        {/* {products.map((product) => ( */}
                          <div key={1} >
                            <img className="hover:grow hover:shadow-lg" src={'https://mercular.s3.ap-southeast-1.amazonaws.com/images/products/2021/11/Product/gopro-hero-10-black.jpg'} style={{width :'100%'}} />
                            <div className="pt-3 flex items-center justify-between">
                              <p className="text-sm">รหัสสินค้า {products.id}</p>
                            
          
                            </div>
                            <div className="pt-3 flex items-center justify-between">
                              <p className="text-sm font-bold"> {products.name}</p>
          
                            </div>
                            <div className="pt-3 flex items-center justify-end">
                            
                              <p className="text-sm">ราคา 5,000 บาท ({products.price} USDT) </p>
                            </div>
                            
                            <div className="pt-3 flex items-center justify-end">
                              <small className="">จำนวนสั่งจองขั้นต่ำที่ต้องการ {products.min} ชิ้น</small>
                              {/* <small className="">มีทั้งหมด {products.max} ชิ้น</small> */}
                            </div>
                            
                            <div className="relative h-4">
                                  <div className="absolute w-full h-full bg-blue-200 rounded-full"></div>
                                  <div className="absolute h-full bg-blue-500 rounded-full" style={{width:`${amount_per}%`}}></div>
                              </div>
                              <div className="pt-1 flex items-center justify-end">
                    
                                <small className="text-gray-900">สั่งจองไปแล้ว {products.qty_current}  ชิ้น</small>
                        
                              </div>
                              <div className="pt-1 flex justify-end">
                              {current < expired ?  <small className="text-gray-900 text-right">เหลือเวลา   
                                  <Countdown
                                    date={new Date(expired) }
                                    renderer={renderer}
                                  /> 
                                  </small>:<div/>
                              }
                              </div>
                              {/* <div className="pt-1 flex justify-center">
                                <p className="text-gray-900 text-right  ">จำนวนที่สั่ง <b className='text-red-600'>{products.user_order ? products.user_order :0}</b> ชิ้น</p>
                              </div> */}
                              
                              {/* <small>คงเหลือ 3 วัน</small> */}
                            {/* <a key={product.id} href={product.href}> buy</a> */}
          
                            {/* {(products.user_order > 0) ? 
          
                                <button disabled={disabled} 
                                      // onClick={()=>onClickCancal()} 
                                      onClick={()=>setOpen(true)} 
                                      className={`${!disabled ? 'bg-blue-500 hover:bg-blue-700' :'bg-blue-200' } text-white font-bold py-2 px-4 rounded-full w-full mt-2`}>
                                  ยกเลิก
                                </button>
                              :  
                              <button disabled={disabled} 
                                      onClick={()=>setOpen(true)} 
                                      className={`${!disabled ? 'bg-red-500 hover:bg-red-700' :'bg-red-200' } text-white font-bold py-2 px-4 rounded-full w-full mt-2`}>
                                    สั่งจอง
                                </button>
                              // <div/>
                            } */}
                            {/* <button disabled={disabled} 
                                      onClick={()=>setOpen(true)} 
                                      className={`${!disabled ? 'bg-red-500 hover:bg-red-700' :'bg-red-200' } text-white font-bold py-2 px-4 rounded-full w-full mt-2`}>
                                    สั่งจอง
                                </button> */}

                          <button disabled={disabled} 
                                  onClick={()=>requestOrder(date_expire)} 
                                  className={`${!disabled ? 'bg-red-500 hover:bg-red-700' :'bg-red-200' } text-white font-bold py-2 px-4 rounded-full w-full mt-2`}>
                                สั่งจอง
                            </button>
                          
                          </div>

                          <div key={1} >
                            <img className="hover:grow hover:shadow-lg" src={'https://mercular.s3.ap-southeast-1.amazonaws.com/images/products/2022/03/Product/audio-pro-bt5-portable-speaker-walnut-top-view.jpg'} style={{width :'100%'}} />
                            <div className="pt-3 flex items-center justify-between">
                              <p className="text-sm">รหัสสินค้า 1536009000001</p>
                            
          
                            </div>
                            <div className="pt-3 flex items-center justify-between">
                              <p className="text-sm font-bold">ลำโพง Audio Pro BT5 Bluetooth Speaker</p>
          
                            </div>
                            <div className="pt-3 flex items-center justify-end">
                            
                              <p className="text-sm">ราคา 10,000 บาท (10 USDT) </p>
                            </div>
                            
                            <div className="pt-3 flex items-center justify-end">
                              <small className="">จำนวนสั่งจองขั้นต่ำที่ต้องการ 5 ชิ้น</small>
                              {/* <small className="">มีทั้งหมด {products.max} ชิ้น</small> */}
                            </div>
                            
                            <div className="relative h-4">
                                  <div className="absolute w-full h-full bg-blue-200 rounded-full"></div>
                                  <div className="absolute h-full bg-blue-500 rounded-full" style={{width:`${amount_per}%`}}></div>
                              </div>
                              <div className="pt-1 flex items-center justify-end">
                    
                                <small className="text-gray-900">สั่งจองไปแล้ว 10  ชิ้น</small>
                        
                              </div>
                              <div className="pt-1 flex justify-end">
                                <small>&nbsp;</small>
                              </div>
                              {/* <div className="pt-1 flex justify-center">
                                <p className="text-gray-900 text-right  ">จำนวนที่สั่ง <b className='text-red-600'>{products.user_order ? products.user_order :0}</b> ชิ้น</p>
                              </div> */}
                              
                              {/* <small>คงเหลือ 3 วัน</small> */}
                            {/* <a key={product.id} href={product.href}> buy</a> */}
          
                            {/* {(products.user_order > 0) ? 
          
                                <button disabled={disabled} 
                                      // onClick={()=>onClickCancal()} 
                                      onClick={()=>setOpen(true)} 
                                      className={`${!disabled ? 'bg-blue-500 hover:bg-blue-700' :'bg-blue-200' } text-white font-bold py-2 px-4 rounded-full w-full mt-2`}>
                                  ยกเลิก
                                </button>
                              :  
                              <button disabled={disabled} 
                                      onClick={()=>setOpen(true)} 
                                      className={`${!disabled ? 'bg-red-500 hover:bg-red-700' :'bg-red-200' } text-white font-bold py-2 px-4 rounded-full w-full mt-2`}>
                                    สั่งจอง
                                </button>
                              // <div/>
                            } */}
                            {/* <button disabled={disabled} 
                                      onClick={()=>setOpen(true)} 
                                      className={`${!disabled ? 'bg-red-500 hover:bg-red-700' :'bg-red-200' } text-white font-bold py-2 px-4 rounded-full w-full mt-2`}>
                                    สั่งจอง
                                </button> */}

                          <button disabled={true} 
                                  className={`bg-green-200 text-white font-bold py-2 px-4 rounded-full w-full mt-2`}>
                                เสร็จสิ้นการขาย
                            </button>
                            
                          
                          </div>
                        {/* ))} */}
                        
                  </div>
            </div>
          </section>
        
          {/* <Modal open={modal} setOpen={setOpen} onClick={onClick} /> */}
        </div>
      </main>
    </>
   
  )
}



export default Dapp;

