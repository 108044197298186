import React,{Fragment,useEffect, useState} from 'react'
import { useWeb3Auth } from "@services/web3auth";
import { useWeb3AuthWallet } from "@services/web3authWallet";
import Web3 from "web3";
import {ABI, ADDRESS} from '@contracts/groupBy'
import { useNavigate,useLocation } from "react-router-dom"; 
// import {useNavigate} from 'react-router-dom';
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import axios from 'axios'
import Swal from 'sweetalert2'
import List from './HistoryList';
import Loader from '@components/Loader';

const transaction = [
    {
      id: 1,
      name: 'All Transactions',
    },
    {
      id: 2,
      name: 'Send',
    },
    {
        id: 3,
        name: 'Receive',
    }
]

const dates = [
    {
      id: 1,
      name: 'All',
    },
    {
      id: 2,
      name: 'Last 1 week',
    },
    {
        id: 3,
        name: 'Last 1 month',
    }
]
  

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
  }
  
const abi:any = ABI

const History = () => {
  const { provider,privateKey } = useWeb3Auth();
  const {web3Auth,WalletConnectProvider,connected} = useWeb3AuthWallet();
  const [loading,setLoading] = useState(false)
  const [Account,setAccount] = useState('');

  const [selectedTrasaction, setSelectedTransaction] = useState(transaction[0])
  const [selectedDate, setSelectedDate] = useState(dates[0])
  const [dataTransaction, setDataTransaction] = useState([])
  const [groupBuy,setGroupBuy] = useState<any>(undefined);
  const navigate = useNavigate();
  
  useEffect(() => {
    const getAccout = async ()=>{
        try {
            const web3 = new Web3(provider);
            const account = web3.eth.accounts.privateKeyToAccount(privateKey);
            setAccount(account.address)
        } catch (error) {
            console.log(error)
        }
    
    }
    if (privateKey)
        getAccout();
    return () => {}
  }, [privateKey, provider]);

  //web3Wallet
  useEffect(() => {
    const getAccout = async ()=>{
        try {
            const providers = WalletConnectProvider ?? web3Auth.provider 
            const web3 = new Web3(providers);
            const account = await web3.eth.getAccounts();

            const groupbuy = new web3.eth.Contract(abi, ADDRESS);
            // const token = new web3.eth.Contract(token_abi, token_address);
            
            setGroupBuy(groupbuy)
            setAccount(account[0])
        } catch (error) {
            console.log(error)
        }
    
    }
    if (connected)
        getAccout();
    return () => {}
  }, [connected]);

  useEffect(() => {
     
    const getTransaction = async()=>{
     
      try {
        await axios.post(`https://cnx-groupby-demo.leafbot.io/api/demo/order/${Account}`, { page: 1, limit:10}).then((res)=>{
            const data = res.data;
            if (data.data){
                const val = data.data;

                setDataTransaction(val)
                // const input =  { 
                //     WalletAddress: val.walletAdress, 
                //     Address: val.shippingAddress,
                //     FirstName:val.firstName,
                //     LastName:val.lastName,
                //     Tel:val.tel,
                //     Email:val.email,
                //     ContactName:val.contractName,
                //     Zipcode:val.zipCode,
                //     ContactTel:val.contactTel
                
                // }
                // setProfile(input)
                // if(input.WalletAddress !== ''){
                //     successful = true;
                // }else{
                //     successful = false;
                // }
               
                // setVerified(val.emailStatus)
                // setDataUser(input);
            }
        })
      } catch (error:any) {
          alert(error.message)
      }
        // await axios.get(`https://api.airtable.com/v0/appUuOWAHjGFOgM4M/requests?filterByFormula=SEARCH("${Account}",WalletAddress)&sort%5B0%5D%5Bfield%5D=requestDate&sort%5B0%5D%5Bdirection%5D=desc`,{
        //         headers: {
        //             'Content-Type': 'application/json;charset=UTF-8',
        //             'accept': 'application/json',
        //             'Authorization': `Bearer keyXS39pJOlAd3YW3`
    
        //  }}).then((response)=>{
       
        //     const res = response.data;
        //     setDataTransaction(res.records)
        // })
        // .catch((error) => {
        //   console.error(error)
        // })
       
    }
    if (Account)  getTransaction()
  
  }, [Account])
 
 
  const onClickCancel = async (contractAddress: any) => {

    await Swal.fire({
        title: 'Message',
        text: `ยกเลิกการจอง`,
        icon: 'warning',
        confirmButtonColor: '#3085d6',
        showCancelButton: true,
        cancelButtonColor: '#d33',
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
        reverseButtons:true,
        
    }).then(async (result) => {
           // // setOpen(false);
           if (result.isConfirmed) {
                setLoading(true)
                try {
                    await groupBuy.methods.cancalOrder().send({
                        from: Account,
                        gas: 210000
                    });
                    setLoading(false)
                    // window.location.reload();
                    await Swal.fire({
                        title: 'Transfer success',
                        text: 'Cancal orders finish',
                        icon: 'success',
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'ok'
                    })
                    // navigate('/dapp')
                    await updateRequest(Account,contractAddress)
                    
                } catch (error:any) {
                    setLoading(false)
                    await Swal.fire({
                        title: 'Transfer fail',
                        text: error.message,
                        icon: 'warning',
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'ok'
                    })
                    
                }
           }
    })

};


    const updateRequest = async (accounts:any,address:any) =>{
        try {
            await axios.post(`https://cnx-groupby-demo.leafbot.io/api/demo/order/${Account}/update`, { walletAddress: accounts, contractAddress:address , status: "ORDER_CANCELED"}).then((res)=>{
                const data = res.data;
                window.location.reload();
                // if (data.data){
                //     const val = data.data;
                //     if (val.code === 'OK'){
                //         window.location.reload();
                //     }
                //     // const input =  { 
                //     //     WalletAddress: val.walletAdress, 
                //     //     Address: val.shippingAddress,
                //     //     FirstName:val.firstName,
                //     //     LastName:val.lastName,
                //     //     Tel:val.tel,
                //     //     Email:val.email,
                //     //     ContactName:val.contractName,
                //     //     Zipcode:val.zipCode,
                //     //     ContactTel:val.contactTel
                    
                //     // }
                //     // setProfile(input)
                //     // if(input.WalletAddress !== ''){
                //     //     successful = true;
                //     // }else{
                //     //     successful = false;
                //     // }
                   
                //     // setVerified(val.emailStatus)
                //     // setDataUser(input);
                // }
            })
            // await axios.get(`https://api.airtable.com/v0/appUuOWAHjGFOgM4M/requests?filterByFormula=SEARCH("${Account}",WalletAddress)`,{
            // headers: {
            //     'Content-Type': 'application/json;charset=UTF-8',
            //     'accept': 'application/json',
            //     'Authorization': `Bearer keyXS39pJOlAd3YW3`

            // }}).then((res)=>{
            //     const data = res.data.records;
            //     data.map((item:any) => {
            //         const input ={
            //             Status:'cancel'
            //         }
            //         const form = {
            //             "records": [
            //                 {
            //                     "id":item.id,
            //                     "fields": input
            //                 }
            //             ]
            //         }
            //         try {
            //             axios.patch(`https://api.airtable.com/v0/appUuOWAHjGFOgM4M/requests`,form,{
            //             headers: {
            //                 'Content-Type': 'application/json;charset=UTF-8',
            //                 'accept': 'application/json',
            //                 'Authorization': `Bearer keyXS39pJOlAd3YW3`
            
            //             }}).then(async (res)=>{
            //                 window.location.reload();
            //             })
            //         } catch (error) {
            //             console.log(error);
            //         }
            //     })
            
                
            // })
        } catch (error) {
            console.log(error);
            
        }
    }


    if (loading){
        return (<Loader/>)
     }
 
 
  return (
    <main>
        <div className="max-w-7xl mx-auto py-6 lg:px-8 ">
            {/* header */}
            <div className='md:flex flex-row py-4 px-2'>
                <div className='flex w-full lg:w-1/2'>
                    <h1 className='mb-2 text-2xl font-bold tracking-tight text-gray-500  sm:text-center '>History</h1>
                </div>
            </div>
            {/* card */}
            <div className="p-4 w-full  bg-white">
                <div className='flex flex-row justify-center'>
                {dataTransaction.length > 0 ?
                     <div className="grid grid-flow-row auto-rows-max w-full">
                         {dataTransaction.map((item:any, index) => (
                             <List account={Account} item={item} index={item.runno} groupBuy={onClickCancel}/>
                        ))}
                    </div>
                    :
                    <div className="text-center text-gray-500">
                        No data available
                    </div>
                }
  
                 
                </div>
            </div>

        
        </div>
    </main>

  )
}

export default History;