import React from 'react'
import moment from 'moment';
import { slicedAddress } from '@utils/convert';
import Web3 from 'web3';

function List({account,item,index}:{account:any,item: any,index: any}) {
  
    let data_type = 'Sent';
    let address = `To ${slicedAddress(item.to)}`;
    let amount = Web3.utils.fromWei(item.value);
    if (account.toLowerCase() === item.to){
        data_type = 'Receive'
        address = `from ${slicedAddress(item.from)}`;
        amount = Web3.utils.fromWei(item.value);
    }else if (item.input !== '0x'){
        data_type = 'Contract'
        address = `To ${slicedAddress(item.to)}`;
        amount = ''
    }
   
    return (
        <div key={index} className='flex flex-row border py-4 my-2 px-4 rounded shadow-md text-gray-500 hover:bg-white/[0.12] hover:text-gray-900 hover:bg-gray-200 cursor-pointer'>
            <div className='w-1/4 flex space-x-2 justify-start flex-col  items-start border-r-2 border-gray-400 mx-4'>
                <div className='text-xs'>{ moment.unix(item.timeStamp).format("D MMM YYYY")}</div>
                <div className='text-xs'>{ moment.unix(item.timeStamp).format("H:mm:ss")}</div>
            </div>
            <div className='w-2/4  flex justify-start flex-col '>
                <div className='text-xs'>{data_type}</div>
                <div className='text-xs'>{address}</div>
            </div>
            <div className={`w-3/4  flex justify-center text-sm ${data_type === 'Sent'?'text-red-500':'text-green-500'}`}>{amount}</div>
            <div className='w-3/4  flex justify-end flex-col items-end'>
                <div className='flex '>
                    {item.isError === '0'?
                    <div className="p-2 text-xs  bg-green-200 text-green-800 rounded-full">Successful</div>
                    :<div className="p-2  text-xs  bg-red-200 text-red-800 rounded-full">Fail</div>
                    }
                </div>
                <a href={`https://testnet.bscscan.com/tx/${item.hash}`} className='flex text-xs hover:text-green-600' target="_blank" rel="noreferrer" >View Explorer</a>
            </div>
        </div>
    )
}

export default List