import React,{Fragment,useEffect, useState} from 'react'
import { useWeb3Auth } from "@services/web3auth";
import { useWeb3AuthWallet } from "@services/web3authWallet";
import Web3 from "web3";
import {useNavigate, useSearchParams} from 'react-router-dom';
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, SelectorIcon,QrcodeIcon,TruckIcon,UserCircleIcon,XCircleIcon } from '@heroicons/react/solid'
import { QrReader } from 'react-qr-reader';
import Swal from 'sweetalert2'
import { CHAIN_CONFIG } from "@config/chainConfig";
import Loader from '@components/Loader';
import Title from '@components/Title'
import {TOKENS} from '@constants/config'
import {ABI as ABITOKEN } from '@contracts/token'
import { EthBalance } from '@components/EthBalance';
import { TokenBalance } from '@components/TokenBalance';

const currentChainConfig = CHAIN_CONFIG['bsc'];

// const MySwal = withReactContent(Swal)
function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

const About = () => {
  const navigate = useNavigate();

  // provider
  const {provider,privateKey } = useWeb3Auth();
  const {web3Auth,WalletConnectProvider,connected } = useWeb3AuthWallet();


  let [searchParams, setSearchParams] = useSearchParams();
  const [selected, setSelected] = useState(TOKENS[0])
  

  const [Account,setAccount] = useState('');
  const [balance,setBlance] = useState<string>('0');
  const [tokenContracts, setTokenContracts] = useState<any>({});
  const [tokenBalances, setTokenBalances] = useState<any>({});
  const [web3,setWeb3] = useState<any>();

  const [isLoading,setIsLoading] = useState<boolean>(false);
  //set form data
  const [address,setAddress] = useState<any>('');
  const [amount,setAmount] = useState<any>(0);
  const [sendAll,setsendAll] = useState<boolean>(false);
  const [showTranfer,setShowTranfer] = useState<boolean>(false);

  const [fee,setFee] = useState<number>(1); //1 Gwei
  const [amountFee,setAmountFee] = useState<any>(21000); //21000 gwei

  const [showModal, setShowModal] = useState(false);
  const [addressError, setAddressError] = useState("");
  const [amountError, setAmountError] = useState("");
  
  
  // web3Auth
  useEffect(() => {
    const getAccout = async ()=>{
      try {
        const web3Provider = new Web3(provider);
        const account = web3Provider.eth.accounts.privateKeyToAccount(privateKey);
        web3Provider.eth.accounts.wallet.add(account);

        setAccount(account.address)
        getTokenContracts(TOKENS,web3Provider)
        setWeb3(web3Provider)

      }catch(error){
        console.log(error);
      }
    }
    if (privateKey)
     getAccout();

    //set defaut  
    return () => {

    }
  }, [privateKey]);

   // web3AuthWallet
  useEffect(() => {
    const getAccout = async ()=>{

      const providers = WalletConnectProvider ?? web3Auth.provider
      try {
        const web3Provider = new Web3(providers);
        const account = await web3Provider.eth.getAccounts();
        setAccount(account[0])
    
        getTokenContracts(TOKENS,web3Provider)
        setWeb3(web3Provider)

      }catch(error){
        console.log(error);
      }
    }
    if (connected)
     getAccout();

    //set defaut  
    return () => {

    }
  }, [connected]);

  useEffect(() => {
    
    if (tokenContracts && web3) {
      setTimeout(() => {
        getTokenBalances()
      }, 1000);
  

       //check token par
      const token_param = searchParams.get("token");
      if (token_param){
        var index:number = TOKENS.findIndex(function(obj) {
          return obj.address === token_param;
      });
      setSelected(TOKENS[index])
      }
      
    }
    return () => {
    }
  }, [tokenContracts,web3])
  
  const getTokenContract = async (symbol: any, token_address: any,web3: any) => {
      const token_abi:any = ABITOKEN
      const token = await new web3.eth.Contract(token_abi, token_address);
      return token
  }

  const getTokenContracts = async (symbols: any[],web3: any) => {
      symbols.map(async (symbol: any) => {
        if (symbol.symbol !== 'BNB'){
          const token_address = symbol.address;
          const contract = await getTokenContract(symbol.symbol,token_address,web3)

          setTokenContracts((prev: any) => ({...prev, [symbol.symbol]:contract}))
        }
        
      })
  }

  const getTokenBalances = () => {
    TOKENS.map(async symbol => {
      let amount:string = '0';
      if (symbol.symbol === 'BNB'){
        try {

          amount =  await web3.eth.getBalance(Account);
        } catch (error) {
          console.log(error);
        }
      
      }else{
        try {
            const contract = tokenContracts[symbol.symbol];
            if (contract){
              amount = await contract.methods.balanceOf(Account).call();
            }

        } catch (error) {
            console.log(error)
        }
      }

      const balance:string = await Web3.utils.fromWei(amount); 
      setTokenBalances((prev: any) => ({...prev, [symbol.symbol]: balance.toString()}))

    })
}

  const sendAllAmount = () =>{
    setsendAll(!sendAll)
    if(sendAll){
      setAmount(0);
    }else{
      setAmount(parseFloat(tokenBalances[selected.symbol]));
    }

    setShowTranfer(true)
  }

  const selectFee = (fee:any) =>{    
    setFee(fee)
    if (fee === 1){
      const gasPrice = fee;
      const gasLimit = 21000;
      const gas:string|number = gasPrice * gasLimit;
      setAmountFee(gas);
    }else{
      const gasPrice = fee;
      const gasLimit = 21000;
      const gas:string|number = gasPrice * gasLimit;
      setAmountFee(gas);
    }
 
  };

  const handleChangeAmount = (event: { target: { value: any; }; }) =>{
    const value = event.target.value.replace(/[^0-9.]/g, "");

    const balance  = parseFloat(tokenBalances[selected.symbol])
   
    if (value > 0 && balance > value){
      setShowTranfer(true)
      setAmountError("") 
  
    }else{
      setShowTranfer(false)
      setAmountError(`Out of Value ${selected.symbol}`)
    }
    setAmount(value)
  }

  const handleChangeAddress = (event: { target: { value: any; }; }) =>{
    const value = event.target.value;
    
    if (!value.startsWith('0x')) {
      setAddressError("Invalid Address field !")  
    }else{
      setAddressError("") 
    }
    setAddress(event.target.value)
   
  }

  const transferData = async ()=>{

    //validate 
    if (address === '' || amount === ''){
      if (!address) setAddressError('Invalid Address')
      if (!amount) setAmountError(`Invalid Value ${selected.symbol}`)
      return false;
    }

    const symbol = selected;    
    if (symbol.name === 'BNB'){  //send native 
      setIsLoading(true)
      try {
          const data = {
            from: Account,
            to: address,
            value: web3.utils.toWei(String(amount),'ether'),
            // gas:String(amountFee),
            // gasPrice: fee,
            // gasLimit: 21000
          }
          const receipt = await web3.eth.sendTransaction(data);
          if (receipt){
            setIsLoading(false)
                await Swal.fire({
                  title: 'Transfer Success',
                  text: 'go to activity',
                  icon: 'success',
                  confirmButtonColor: '#3085d6',
                  confirmButtonText: 'click'
                })
                .then((result) => {
                  if(result.value){
                    navigate('/activity', {replace: true});
                }
              })
          }
      } catch (error:any) {
        setIsLoading(false)

        await Swal.fire({
          title: 'Transfer fail',
          text: error.message,
          icon: 'warning',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'ok'
        })
      }
    }else{ //send token
      setIsLoading(true)
      try {
        const contract = tokenContracts[selected.symbol];
        await contract.methods.transfer(address, web3.utils.toWei(String(amount),'ether')).send({
          from: Account,
          // gas:String(`${amountFee}0`),
          // gasPrice: fee,
          // gasLimit: 21000
          // gas: String(8000000),
          // gasPrice: gasPrice,
          // gasLimit: 21000,
        }).then(async (res: any) => {
          setIsLoading(false)
            await Swal.fire({
              title: 'Transfer Success',
              text: 'go to activity',
              icon: 'success',
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'click'
            })
            .then((result) => {
              if(result.value){
                navigate('/activity', {replace: true});
            }
          })
        }).catch(async (err: any)=> {
            setIsLoading(false)

            await Swal.fire({
              title: 'Transfer fail',
              text: err.message,
              icon: 'warning',
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'ok'
            })
        });

      } catch (error:any) {
        setIsLoading(false)

        await Swal.fire({
          title: 'Transfer fail',
          text: error.message,
          icon: 'warning',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'ok'
        })
      }
    }
 
 
  }


  
  //cal total
  let total:any = 0;
  if (amount > 0){
    const weiValue = Web3.utils.toWei(String(amountFee), 'gwei');
    total = parseFloat(amount) + parseFloat(web3.utils.fromWei(String(weiValue), 'ether'));
  }

  if (isLoading){
    return <Loader/>
  }
 
  if (!connected){
    if (!privateKey) return <div/>
  }
  

  return (
    <main>
      <div className="max-w-7xl mx-auto py-2 sm:px-6 lg:px-8 sm:px-4">
        {/* header */}
        <Title accout={Account} title={'Transfer Details'}/>
        {/* card */}
        <div className="md:flex flex-row-reverse">
                    
          <div className="p-4 w-full md:h-40 mb-6  bg-white rounded-lg border shadow-md sm:p-6">
            <div className='flex flex-row'>
              <div className='basis-1/2'>
                <h1 className='mb-2 text-1xl font-bold tracking-tight text-gray-900 '>Account Balances</h1>
                <div className='flex flex-row'>
                  {connected ?  //web3 connect
                   <div>
                     <p className='text-4xl font-bold text-gray-500'>{ tokenBalances[selected.symbol] ? parseFloat(tokenBalances[selected.symbol]).toFixed(2) : 0}</p>
                    <small className='justify-end items-end flex'>{selected.symbol}</small>
                   </div>
                    :
                    tokenContracts[selected.symbol] ? 
                    <div className='flex'><TokenBalance address={tokenContracts[selected.symbol]._address} decimals={18} account={Account} className='text-4xl font-bold text-gray-500'/><small className='justify-end items-end flex'>{selected.symbol}</small> </div>
                    :<div className='flex'><EthBalance privateKey={privateKey}/><small className='justify-end items-end flex'>{selected.symbol}</small> </div>
                    
                  }
                
                
                </div>
              </div>
              <div className='basis-1/2 text-right'>
                <span className="inline-flex items-center justify-center px-2 py-1 text-xs hover:text-xs font-medium  text-white bg-red-500 rounded">{currentChainConfig.displayName}</span>
              </div>
            </div>
          </div>

          <div className="p-4 w-full  bg-white rounded-lg border shadow-md sm:p-6">

            <div className='flex flex-col'>
                <div className='pb-6'>
                  <p className='mb-2 text-base tracking-tight text-gray-900 '>Select item</p>
                  <div className=''>
                    <div className="relative flex w-full flex-wrap items-stretch h-12">
                        <Listbox value={selected} onChange={setSelected}>
                          {({ open }) => (
                            <>
                              <div className="mt-1 relative w-full">
                                <Listbox.Button className="relative w-full h-12 bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none sm:text-sm">
                                  <span className="flex items-center">
                                    <img src={selected.avatar} alt="" className="flex-shrink-0 h-6 w-6 rounded-full" />
                                    <span className="ml-3 block truncate">{selected.name}</span>
                                  </span>
                                  <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                    <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                  </span>
                                </Listbox.Button>

                                <Transition
                                  show={open}
                                  as={Fragment}
                                  leave="transition ease-in duration-100"
                                  leaveFrom="opacity-100"
                                  leaveTo="opacity-0"
                                >
                                  <Listbox.Options className="absolute z-50 mt-1 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                    {TOKENS.map((person) => (
                                      <Listbox.Option
                                        key={person.id}
                                        className={({ active }) =>
                                          classNames(
                                            active ? 'text-white bg-indigo-600' : 'text-gray-900',
                                            'cursor-default select-none relative py-2 pl-3 pr-9'
                                          )
                                        }
                                        value={person}
                                      >
                                        {({ selected, active }) => (
                                          <>
                                            <div className="flex items-center">
                                              <img src={person.avatar} alt="" className="flex-shrink-0 h-6 w-6 rounded-full" />
                                              <span
                                                className={classNames(selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate')}
                                              >
                                                {person.name}
                                              </span>
                                            </div>

                                            {selected ? (
                                              <span
                                                className={classNames(
                                                  active ? 'text-white' : 'text-indigo-600',
                                                  'absolute inset-y-0 right-0 flex items-center pr-4'
                                                )}
                                              >
                                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                              </span>
                                            ) : null}
                                          </>
                                        )}
                                      </Listbox.Option>
                                    ))}
                                  </Listbox.Options>
                                </Transition>
                              </div>
                            </>
                          )}
                        </Listbox>
                    </div>
                  
                  </div>
                </div>
                <div className='pb-6'>
                  <p className='mb-2 text-base tracking-tight text-gray-900 '>Transfer to</p>
                  <div className='flex w-full'>
                    <div className="relative flex w-full flex-wrap items-stretch ">
                      <input type="text" onChange={handleChangeAddress} value={address} className={` ${addressError  != '' ? 'border-red-500':''} h-12  border rounded px-2 py-1 placeholder-slate-300 text-slate-600 relative bg-white text-sm shadow outline-none focus:outline-none  w-full pr-10`}/>
                      <span   onClick={() => setShowModal(true)} className="cursor-pointer z-10 h-full leading-snug font-normal absolute text-center text-slate-300 absolute bg-transparent rounded text-base items-center justify-center w-8 right-0 p-1 py-3">
                        <QrcodeIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                        {/* <Test/> */}
                      </span>
                      {
                        addressError != '' ?  
                        <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                          {addressError}
                        </span>: ""
                      }
                     
                    </div>
                  
                  </div>
                </div>
                <div className='pb-4'>
                  <div className='flex flew-col justify-between'>
                    <p className='mb-2 text-base tracking-tight text-gray-900 '>You send</p>
                    {sendAll ?  <button type="button" onClick={()=>sendAllAmount()} className='mb-2 text-base tracking-tight text-blue-400 '>Reset</button> :
                                <button type="button" onClick={()=>sendAllAmount()} className='mb-2 text-base tracking-tight text-blue-400 '>Send All</button>
                    }
                  
                  </div>
                  <div className='flex w-full'>
                    <div className="relative flex w-full flex-wrap items-stretch ">
                      <input  onChange={handleChangeAmount} type="text" className={`${amountError ? 'border-red-500':''} h-12 border rounded px-2 py-1 placeholder-slate-300 text-slate-600 relative bg-white rounded text-sm shadow outline-none focus:outline-none  w-full pr-10`} value={amount}/>
                      {/* <span className="z-10 h-full leading-snug font-normal absolute text-center text-slate-300 absolute bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-2 py-1">
                        <i className="fas fa-user"></i>
                      </span> */}
                      {
                        amountError ?  
                        <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                          {amountError}
                        </span>: ""
                      }
                    </div>
                  </div>
                </div>
                <div className='pb-4'>
                  <p className='mb-2 text-base tracking-tight text-gray-900 '>Transaction Fee</p>
                  <div className='flex w-full'>
                    <button onClick={()=>selectFee(1)} className={`flex justify-center w-1/2 border-2 ${fee === 1 ? "border-blue-500" : " hover:border-blue-300"}  rounded h-16 mx-2 items-center shadow-md`}>
                      <UserCircleIcon className="h-8 w-10 text-gray-400" aria-hidden="true" />
                      <small className='text-center text-xs'>~1 mins <br />Pay 0.000021</small>
                    </button>
                    <button onClick={()=>selectFee(6)} className={`flex justify-center w-1/2 border-2 ${fee === 6 ? "border-blue-500" : " hover:border-blue-300"}  rounded h-16 mx-2 items-center shadow-md`}>
                      <TruckIcon className="h-8 w-10 text-gray-400" aria-hidden="true" />
                      <small className='text-center text-xs'> {'< 1 mins'} <br />Pay 0.00013</small>
                    </button>
                  </div>
                </div>
              
            </div>

            <div className="flex flex-row-reverse mt-2">
              <div className='flex flex-col'>
                <div className='text-right'>
                  Total Cost
                </div>
                <div className='font-bold'>
                  ~ { parseFloat(total).toFixed(4)} {selected.symbol}
                </div>
              </div>
            </div>
            <div className="flex flex-row-reverse mt-8">
              <button onClick={transferData} disabled={!showTranfer} className={`${showTranfer?'bg-blue-500':'bg-blue-200'} text-white font-bold text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150`} type="button">
                Transfer
              </button>
            </div>
          </div>

        </div>
        

        {/* //read qrcode */}
        {showModal ? (
        <>
          <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="flex items-start justify-end pt-2 rounded-t ">
                  <button
                    className="bg-transparent border-0 text-black float-right"
                    onClick={() => setShowModal(false)}
                  >
                    <XCircleIcon className="h-8 w-10 text-gray-400" aria-hidden="true" />
                  </button>
                </div>
                  <div className="relative flex-auto">
                    <div className='text-center text-2xl font-bold'>Scan QR</div>
                    <QrReader
                      onResult={(result:any, error) => {
                        if (!!result) {
                          setAddress(result.text);
                          setShowModal(false);
                        }
                        
                        if (!!error) {
                          console.info(error);
                        }
                      }}
                      containerStyle={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: `350px`,
                        height:`350px`,
                        overflow: "hidden",
                        // border: `4px solid ${isValidData ? "green" : "red"}`,
                        // borderRadius: "4px",
                      }}
                      constraints={{
                        facingMode: "environment",
                      }}
                      // className="shadow"
                    />
                  </div>
              </div>
            </div>
          </div>
        </>
        ) : null}
    
      </div>
    </main>
  )
}



export default About;