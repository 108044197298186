/* eslint-disable max-len */
import React,{useEffect,useState,Fragment} from "react";
import { Link,useLocation,useNavigate } from "react-router-dom";
import { useWeb3Auth } from "@services/web3auth";
import { useWeb3AuthWallet } from "@services/web3authWallet";
import { ChevronDownIcon,HomeIcon,ViewListIcon,ShoppingCartIcon,CurrencyDollarIcon } from '@heroicons/react/solid'
import Loader from "@components/Loader";
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { BellIcon, MenuIcon, XIcon } from '@heroicons/react/outline'
import Web3 from "web3";


const navigation = [
  { name: 'Home', href: '/', current: true ,icon: <HomeIcon className="block h-6 w-6" aria-hidden="true" />},
  { name: 'Transfer', href: '/transfer', current: false,icon: <CurrencyDollarIcon className="block h-6 w-6" aria-hidden="true" /> },
  { name: 'Activity', href: '/activity', current: false,icon: <ViewListIcon className="block h-6 w-6" aria-hidden="true" /> },
  { name: 'Market', href: '/dapp', current: false,icon: <ShoppingCartIcon className="block h-6 w-6" aria-hidden="true" /> },
  { name: 'Order History', href: '/dapp/history', current: false,icon: <ShoppingCartIcon className="block h-6 w-6" aria-hidden="true" /> },
]

const logo:any = require('@assets/BNAsset43.png');

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

const Header = () => {
  const { logoutWallet,getUserWallet,connected,user,web3Auth } = useWeb3AuthWallet();
  const { provider,privateKey, logout,getUserInfo } = useWeb3Auth();
  const [users,setUsers] = useState<any>();
  const [Account,setAccount] = useState()
  const location = useLocation()
  
  const local = location.pathname.split('/')[1];
  const local_url = location.pathname.split('/')[2];
  let path = ''
  if (local_url == 'history'){
    path =  `/${local}/${local_url}`;
  }else{
    path =  `/${local}`;
  }
  const adpter:any = user;

  const navigate = useNavigate();
  useEffect(() => {
    const getUser = async ()=>{
      const web3:any =  new Web3(provider);
     
      // let balances = await wallet.getBalance();
      // console.log(balances);
      // customHttpProvider.getBlockNumber().then((result) => {
      //   console.log("Current block number: " + result);
      // })
 
      const account = web3.eth.accounts.privateKeyToAccount(privateKey);
      setAccount(account);
      const user = await getUserInfo()
      setUsers(user);
    }
    if (privateKey){
      getUser();
    }
    
    return () => {}
  }, [privateKey])
   
  //web3Wallet
  useEffect(() => {
    const getUser = async ()=>{
      const web3:any = await new Web3(web3Auth.provider);
      const account = await web3.eth.getAccounts();
      setAccount(account[0]);

      const user = await getUserWallet(adpter.adapter)
      setUsers(user);
    }
    if (connected)
      getUser();

    return () => {}
  }, [connected, getUserWallet])

  const logoutApp =  async ()=>{
    if (privateKey){
      await logout()
    }else{
      await logoutWallet()
    }
    
  }

  if (!users){
    return <Loader/>
  }
  
  return (
      <Disclosure as="nav" className="bg-red-600">
        {({ open }) => (
          <>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="flex items-center justify-between h-16">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <img
                      className="h-14 w-14"
                      src={logo}
                      alt="Workflow"
                    />
                  </div>
                  <div className="hidden md:block">
                    <div className="ml-10 flex items-baseline space-x-4">
                      {navigation.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className={classNames(
                            item.href == path
                              ? 'text-white'
                              : 'text-red-300  hover:text-white',
                            'px-3 py-2 rounded-md text-sm font-medium'
                          )}
                          aria-current={item.href == path ? 'page' : undefined}
                        >
                          {item.name}
                        </a>
                      ))}
                      <a href="https://testnet.bscscan.com/" target="_blank" className="text-red-300  hover:text-white px-3 py-2 rounded-md text-sm font-medium" rel="noreferrer">Explorer</a>
                    </div>
                  </div>
                </div>
                <div className="hidden md:block">
                  <div className="ml-4 flex items-center md:ml-6">


                  <Menu as="div" className="relative inline-block text-left">
                    <div>
                      <Menu.Button className="inline-flex w-full justify-center py-2 text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                      <span className="relative inline-block">
                            <BellIcon className="block h-6 w-6 text-white" aria-hidden="true" />
                            <span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white transform translate-x-1/2 -translate-y-1/2 bg-black-600 rounded-full">
                              0
                            </span>
                          </span>
                       
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="px-1 py-1 ">
                          <Menu.Item>
                            {({ active }) => (
                              <button
                                className={`text-gray-900 group flex w-full items-center rounded-md px-2 py-2 text-sm justify-center`}
                              >
                              no notifications
                              </button>
                            )}
                          </Menu.Item>
                         
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>  
                    {/* Profile dropdown */}
                    <Menu as="div" className="ml-3 relative">
                      <div className="flex flex-row">
                      
                        <Menu.Button className="max-w-xs rounded-full flex items-center text-sm focus:outline-none text-white ">
                          <span className="sr-only">Open user menu</span>
                          {/* <img className="h-8 w-8 rounded-full" src={user.imageUrl} alt="" /> */}
                          
                          {users.name}
                          <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
     
                        </Menu.Button>
                      
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                         <Menu.Items className="z-50 origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                         <div className="py-1">
                           <h1 className="px-4 text-left">Account</h1>
                           <div className="flex flex-row py-2 px-4">
                             {/* <p>{users.email} </p> */}
                           </div>
                           <hr />
                           {/* <form method="POST" action="#"> */}
                             <Menu.Item>
                               {({ active }) => (
                                  <button
                                    onClick={()=>navigate(`/profile/${Account}`)}
                                    type="button"
                                    className={classNames(
                                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                      'block w-full text-right px-4 py-2 text-sm'
                                    )}
                                  >
                                    Edit Profile
                                  </button>
                               )}
                               
                             </Menu.Item>
                             <Menu.Item>
                               {({ active }) => (
                                 <button
                                   onClick={logoutApp}
                                   type="button"
                                   className={classNames(
                                     active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                     'block w-full text-right px-4 py-2 text-sm'
                                   )}
                                 >
                                   Sign out
                                 </button>
                               )}
                             </Menu.Item>
                           {/* </form> */}
                         </div>
                       </Menu.Items>
                      
                      </Transition>
                    </Menu>
                  </div>
                </div>
                <div className="-mr-2 flex md:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-white hover:text-white  focus:outline-none">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="md:hidden">
              <div className="pt-4 pb-3">
                <div className="flex items-center px-5 justify-center flex-col">
                 
                  <div className="ml-2">
                    <div className="text-lg font-bold leading-none text-white mb-2 text-center">{users.name}</div>
                    <div className="text-sm font-medium leading-none text-white text-center">{users.email}</div>
                  </div>
                  <div className="flex justify-center">
                    <a href={`/profile/${Account}`}  className="text-white text-sm">Edit Profile</a>
                  </div>
                 
                </div>
              
              </div>
              <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                {navigation.map((item) => (
                  <Disclosure.Button
                    key={item.name}
                    as="a"
                    href={item.href}
                    className={classNames(
                       item.href == path ? 'text-white' : 'text-gray-300 hover:bg-gray-300 hover:text-white',
                      'block px-3 py-2 rounded-md text-base font-medium'
                    )}
                    aria-current={ item.href == path ? 'page' : undefined}
                  >
                    <div className="flex flex-row">                       
                        {item.icon}
                        {item.name}
                    </div>
                   
                    
                  </Disclosure.Button>
                ))}
                <Disclosure.Button
                    key={'exploder'}
                    as="a"
                    target={'_blank'}
                    href={'https://evm-explorer.omplatform.com'}
                    className={classNames(
                        'text-gray-300 hover:bg-gray-300 hover:text-white',
                      'block px-3 py-2 rounded-md text-base font-medium'
                    )}
                    aria-current={undefined}
                  >
                    <div className="flex flex-row">                       
                    <ViewListIcon className="block h-6 w-6" aria-hidden="true" />
                        Explorer
                    </div>
                   
                    
                  </Disclosure.Button>
                 {/* <a href="https://evm-explorer.omplatform.com" target="_blank" className="text-red-300  hover:text-white px-3 py-2 rounded-md text-sm font-medium" rel="noreferrer">Explorer</a> */}
              </div>
              <div className="mt-3 px-4 space-y-1 border-t border-white text-right py-2">
                  <button
                      onClick={logoutApp}
                      type="button"
                      className={classNames(
                        'text-white',
                        'block w-full text-right px-4 py-2 text-md font-bold'
                      )}
                    >
                      Sign out
                    </button>
                </div>
             
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    )

  // return (
  //   <header>
  //     <nav id="header" className="w-full z-30 top-0 py-1 bg-red-600 h-20">
  //       <div className="flex flex-row">
  //         <div className="flex justify-center items-center w-14 hidden lg:block  self-center">
  //             <div className="text-xl font-semibold text-gray-700 ">
  //                 {/* <a href="/" className="text-gray-800 text-xl font-bold hover:text-gray-700 md:text-2xl">Brand</a> */}
  //                 <img src={logo} alt="logo" width={'100%'} className="m-4" />
  //             </div>
  //             {/* <div className="flex md:hidden">
  //                 <button type="button" className="text-gray-500 hover:text-gray-600 focus:outline-none focus:text-gray-600" aria-label="toggle menu">
  //                     <svg viewBox="0 0 24 24" className="h-6 w-6 fill-current">
  //                         <path fill-rule="evenodd" d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"></path>
  //                     </svg>
  //                 </button>
  //             </div> */}
  //         </div>
  //         <div className="w-full container mx-auto flex flex-wrap items-center justify-between mt-0 py-6 py-3">
  //             <label htmlFor="menu-toggle" className="cursor-pointer md:hidden block m-2">
  //               <svg className="fill-current text-gray-900" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  //                 <title>menu</title>
  //                 <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
  //               </svg>
  //             </label>
  //             <input className="hidden" type="checkbox" id="menu-toggle" />
  //             <div className="hidden md:flex md:items-center md:w-auto w-full order-3 md:order-1" id="menu">
  //               <nav>
  //                 <ul className="md:flex items-center justify-between text-base text-gray-700 pt-4 md:pt-0">
  //                   <li><Link className="inline-block no-underline hover:text-white hover:underline py-2 px-4 text-white" to="/">Home</Link></li>
  //                   <li><Link className="inline-block no-underline hover:text-white hover:underline py-2 px-4 text-white" to="/tranfer">Tranfer</Link></li>
  //                   <li><Link className="inline-block no-underline hover:text-white hover:underline py-2 px-4 text-white" to="/activity">Activity</Link></li>
  //                   <li><Link className="inline-block no-underline hover:text-white hover:underline py-2 px-4 text-white" to="/dapp">Dapp</Link></li>
  //                   <li><a href="https://evm-explorer.omplatform.com" target="_blank" className="inline-block no-underline hover:text-white hover:underline py-2 px-4 text-white" rel="noreferrer">Explorer</a></li>
                    
  //                 </ul>
  //               </nav>
  //             </div>
    
  //             <div className="order-2 md:order-3 flex items-center" id="nav-content">
  //                 {/* <button onClick={logout} className="py-2  text-sm font-normal text-white rounded-md w-40 bg-blue-600 hover:bg-blue-800 float-right ml-2">Disconnect</button>  */}
  //                 <Menu as="div" className="relative inline-block text-left">
  //                   <div>
  //                     <Menu.Button className="inline-flex justify-center w-full  px-4 py-2 text-sm font-medium text-white">
  //                       {user.name}
  //                       <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
  //                     </Menu.Button>
  //                   </div>

  //                   <Transition
  //                     as={Fragment}
  //                     enter="transition ease-out duration-100"
  //                     enterFrom="transform opacity-0 scale-95"
  //                     enterTo="transform opacity-100 scale-100"
  //                     leave="transition ease-in duration-75"
  //                     leaveFrom="transform opacity-100 scale-100"
  //                     leaveTo="transform opacity-0 scale-95"
  //                   >
  //                     <Menu.Items className="z-50 origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
  //                       <div className="py-1">
  //                         <h1 className="px-4 text-left">Account</h1>
  //                         <div className="flex flex-row py-2 px-4">
  //                           <p>{user.email} </p>
  //                         </div>
  //                         <hr />
  //                         {/* <form method="POST" action="#"> */}
  //                           <Menu.Item>
  //                             {({ active }) => (
  //                               <button
  //                                 onClick={logout}
  //                                 type="button"
  //                                 className={classNames(
  //                                   active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
  //                                   'block w-full text-right px-4 py-2 text-sm'
  //                                 )}
  //                               >
  //                                 Sign out
  //                               </button>
  //                             )}
  //                           </Menu.Item>
  //                         {/* </form> */}
  //                       </div>
  //                     </Menu.Items>
  //                   </Transition>
  //                 </Menu>
  //             </div>
  //         </div>
  //       </div>
  //     </nav>
  //   </header>
  // );
}
export default Header;
