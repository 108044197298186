/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationIcon,QrcodeIcon } from '@heroicons/react/outline'
import QRCode from "react-qr-code";


function base64SvgToBase64Png (originalBase64: string, width: number) {
    return new Promise(resolve => {
        let img = document.createElement('img');
        img.onload = function () {
            document.body.appendChild(img);
            let canvas = document.createElement("canvas");
            let ratio = (img.clientWidth / img.clientHeight) || 1;
            document.body.removeChild(img);
            canvas.width = width;
            canvas.height = width / ratio;
            let ctx:any = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
            try {
                let data = canvas.toDataURL('image/png');
                resolve(data);
            } catch (e) {
                resolve(null);
            }
        };
        img.src = originalBase64;
    });
}

export default function Modal(props: { account: any,setActive:any,openReceive:any }) {

    // const [open, setOpen] = useState(false)
    const account =props.account
    const cancelButtonRef = useRef(null)
    const setActive = props.setActive;
    const openReceive = props.openReceive;
    

    const setopenActive = (status:boolean) => {
        setActive(status)
    }
    
    // download QR code
    const downloadQRCode = async() => {
        const canvas:any = document.getElementById('qrCodeEl');
        var xml = new XMLSerializer().serializeToString(canvas);
        var svg64 = btoa(xml); //for utf8: btoa(unescape(encodeURIComponent(xml)))
        var b64start = 'data:image/svg+xml;base64,';
        var image64 = b64start + svg64;
        const dd = await base64SvgToBase64Png(image64,400)
        const downloadLink = document.createElement("a");
        downloadLink.download = "qrcode";
        downloadLink.href = `${dd}`;
        downloadLink.click();
    }
 
    return (
        <>
            {/* <div onClick={()=>setopenActive(true)} className="ml-2 cursor-pointer">
                <QrcodeIcon className="h-6 w-6 text-gray-500" aria-hidden="true" />
            </div> */}
            <Transition.Root show={openReceive} as={Fragment}>
                <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={setopenActive}>
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className="relative inline-block align-bottom bg-white rounded-lg text-center overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                        <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 justify-center">
                            <div className="">
                                {/* <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                    <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                                </div> */}
                                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900 justify-center flex">
                                    Your Public Address
                                    </Dialog.Title>
                                    <div className="mt-2 text-center mb-4">
                                        <p className='text-xs'>{account}</p>
                                        {/* <span onClick={()=>copyToClipboard()}>copyToClipboard</span> */}
                                    </div>
                                    <div className='justify-center flex HpQrcode'>
                                        <QRCode value={account} id="qrCodeEl" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row justify-center">
                            <button
                            type="button"
                            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                            onClick={() => downloadQRCode()}
                            >
                            Download QR
                            </button>
                           
                        </div>
                        </div>
                    </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
      
    )
}
