/* eslint-disable max-len */
import React,{Fragment,useEffect, useState} from 'react'
import { WALLET_ADAPTERS } from "@web3auth/base";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from '../Layout/Layout';
import { FormEvent } from "react";
import { useWeb3Auth } from "@services/web3auth";
import { useWeb3AuthWallet } from "@services/web3authWallet";
import Loader from "../Loader";
import Form from "./elements/Form";
import styles from "@styles/Home.module.css";
import queryString from 'query-string'

import Home from '@pages/Home'
import Tranfer from '@pages/Tranfer'
import Dapp from '@pages/Dapp'
import Activity from '@pages/activity'
import Profile from '@pages/profile'

import Product from '@pages/Dapp/products/Detail'
import Comfirmed from '@pages/Dapp/products/Comfirmed'
import History from '@pages/Dapp/products/History'

import axios from 'axios'
import Web3 from 'web3';
import Manage from '@pages/manage';
import DetailManage from '@pages/detailManage';
import Reader from '@components/Reader/Index'

function Router() {
  return (
      <BrowserRouter>
      <Layout>
        <Routes>
          <Route path="/" element={<Home/>}/>
          <Route path="/transfer" element={<Tranfer/>} />
          <Route path="/activity" element={<Activity/>} />
          <Route path="/dapp" element={<Dapp/>} />
          <Route path="/dapp/products" element={<Product/>} />
          <Route path="/dapp/confirmed" element={<Comfirmed/>} />
          <Route path="/dapp/history" element={<History/>} />
          
          <Route path="/profile/:WalletAddress" element={<Profile/>} />

          <Route path="/manage" element={<Manage/>} />
          <Route path="/detail_manage" element={<DetailManage/>} />
          <Route path="/qrcode" element={<Reader/>} />
          
        </Routes>
      </Layout>
    </BrowserRouter>
  )
}

const Index = () => {
  const { provider,privateKey, login, logout, getUserInfo, getAccounts, getBalance, signMessage, isLoading,user } = useWeb3Auth();

  const { loginWallet,loginWithWalletConnect, isWallet,connected } = useWeb3AuthWallet();
 
  const connectedWeb3Auth = localStorage.getItem('isWalletConnected');

  
  useEffect(() => {
 
    //redirect login with email
    const getLogin = async ()=>{
      if (!isLoading && connectedWeb3Auth !== 'true'){
        const values = queryString.parse(window.location.search)
        if (values.login){
          const email:any = values.login;
          setTimeout(async () => {
            login(WALLET_ADAPTERS.OPENLOGIN, "email_passwordless", email);
          }, 5000);
         
        }
      }
    }
    getLogin()
   
    return () => {
      
    }
  }, [isLoading])


  //active user 
  useEffect(() => {
    const activeUser = async()=>{
      const user_data = await getUserInfo()
      const web3:any = await new Web3(provider);
      const account = web3.eth.accounts.privateKeyToAccount(privateKey);

      await axios
        .get(`https://hook.integromat.com/vwmwztoo0breq297dd9p7kqou63e141w?&email=${user_data.email}&type=${user_data.typeOfLogin}&action=register`)
        .then(async (response: { data: any; }) => {
          const res = response.data;
          if (res.success === 'true'){
            const defaultPublicAddress = account.address
            await axios
            .get(
              `https://hook.integromat.com/vwmwztoo0breq297dd9p7kqou63e141w?email=${user_data.email}&address=${defaultPublicAddress}&type=${user_data.typeOfLogin}&action=check_wallet`
            )
            .then((response) => {
              // console.log('log:', response.data)
              console.log(response.data)
            })
            .catch((error) => {
              console.error(error)
            })
          }
        })
        .catch((error: any) => {
          console.error(error)
        })
    }
    const users:any = user;
    if (user && !users.reconnected)  activeUser()
    if (user) localStorage.setItem('isWalletConnected', 'true')
  }, [user,privateKey])
  

  const handleLoginWithEmail = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const email = (e.target as any)[0].value
    login(WALLET_ADAPTERS.OPENLOGIN, "email_passwordless", email);
  }
  
  const loggedInView = (
    <>
      <button onClick={getUserInfo} className={styles.card}>
        Get User Info
      </button>
      <button onClick={getAccounts} className={styles.card}>
        Get Accounts
      </button>
      <button onClick={getBalance} className={styles.card}>
        Get Balance
      </button>
      <button onClick={signMessage} className={styles.card}>
        Sign Message
      </button>
      <button onClick={logout} className={styles.card}>
        Log Out
      </button>

      <div className={styles.console} id="console">
        <p className={styles.code}></p>
      </div>
    </>
  );
    
  return isLoading || isWallet ?
    (
      <Loader/>
    ): (
      <>
       <div className="min-h-full">
         {
           (connectedWeb3Auth === 'true') || (connected) ? <Router/> : <Form login={login} loginWallet={loginWallet} loginWalletConnect={loginWithWalletConnect} handleLoginWithEmail={handleLoginWithEmail}/>
          }
        </div>
      </>
    )
};

export default Index;
